/*!
 * Docaposte theme
 * Copyright 2020 Docaposte
 * Based on Bootstrap 4+
 */
@import "~@angular/cdk/overlay-prebuilt.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

/** Start Docapost surchage Bootstrap **/
@import "assets/styles/basics/fonts";
@import "assets/styles/themes/docaposte-variables";
@import "assets/styles/basics/functions";
@import "assets/styles/basics/mixins";
@import "assets/styles/basics/types";
//@import "assets/styles/themes/docaposte-theme";
/** End Docapost surchage Bootstrap **/

/** Start bootstrap imports **/
@import "bootstrap-scss/bootstrap";
/** End bootstrap imports **/

/** Start Docapost design-system imports **/
@import "assets/styles/basics/breadcrum";
@import "assets/styles/basics/font-icon";
@import "assets/styles/basics/buttons";
@import "assets/styles/basics/card";
@import "assets/styles/basics/forms";
@import "assets/styles/basics/progress";
@import "assets/styles/basics/stepper";
@import "assets/styles/basics/toast";
@import "assets/styles/basics/utilities";
@import "assets/styles/basics/file-upload";
@import "assets/styles/basics/checkboxes";
@import "assets/styles/basics/modal";
@import "assets/styles/basics/table";
@import "assets/styles/basics/date-picker";
@import "assets/styles/basics/dropdown";
@import "assets/styles/basics/navbar";
@import "assets/styles/basics/sign-pad";
@import "assets/styles/basics/separator";
@import "assets/styles/basics/root";
@import "assets/styles/basics/tooltip";
/** End Docapost design-system imports **/
@import "../node_modules/flag-icon-css/css/flag-icons.min.css";
@import "assets/styles/main";
