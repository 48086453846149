// mixins Docaposte

/* generation de classes pour des couleurs de texte uniquement */
@mixin text-color-variant($parent, $color) {
  #{$parent} {
    color: #{$color} !important;
  }
}
@mixin background-color-variant($parent, $color) {
  #{$parent} {
    background: #{$color} !important;
  }
}
