//
// Date picker NGBootstrap
//
.datepicker {
  //month datepicker
  .ngb-dp-month .ngb-dp-month-name {
    color: $datepicker-month-name-color;
    font-family: $datepicker-month-name-font-family;
    font-size: $datepicker-month-name-font-size;
  }
  //week datepicker
  .ngb-dp-week.ngb-dp-weekdays .ngb-dp-weekday,
  .ngb-dp-week.ngb-dp-weekdays .ngb-dp-small {
    color: $datepicker-weekdays-name-color;
    font-family: $datepicker-weekdays-name-font-family;
    font-size: $datepicker-weekdays-name-font-size;
    font-style: normal;
  }
  .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday {
    width: $datepicker-indicator-width !important;
    height: $datepicker-indicator-height !important;
    display: table;
  }
  .ngb-dp-week .custom-day {
    width: $datepicker-indicator-width !important;
    height: $datepicker-indicator-height !important;
    display: table-cell !important;
    vertical-align: middle;
    text-align: center;
  }
  //indicator datepicker
  .custom-day {
    position: relative;
    //selected
    &.range {
      background: $datepicker-indicator-selected-bg !important;
      color: $datepicker-indicator-selected-color !important;
      border-radius: $datepicker-indicator-selected-border-radius;
      &.faded {
        background: $datepicker-indicator-bg !important;
        color: $datepicker-indicator-color !important;
        border-radius: $datepicker-indicator-border-radius;
      }
    }
    &:hover:not(.range) {
      border-radius: 0 $datepicker-indicator-selected-border-radius $datepicker-indicator-selected-border-radius 0;
      background: $datepicker-indicator-hover-bg !important;
      color: $datepicker-indicator-hover-color;
    }
    &.start:before,
    &.range.focused:before,
    &.end:before {
      content: "";
      background: $datepicker-indicator-bg;
      width: 50%;
      height: 100%;
      position: absolute;
      z-index: -1;
      top: 0;
    }
    &.start:before,
    &.range.focused:not(.end):before {
      left: 50%;
    }
    &.end:before {
      left: 0;
    }
  }
}
