.progress {
  overflow: visible;
  margin-bottom: 3rem;
}

.progress-bar {
  position: relative;
  overflow: visible;
  color: $progress-bar-color;
  border-radius: $progress-border-radius;
  span {
    position: absolute;
    bottom: calc(-100% - 1rem);
    right: 0;
    transform: translateX(50%);
    font-family: $progress-font-family;
  }
}
