.stepper {
  --stepper-selected-bg: #{$stepper-item-selected-bg};
  --stepper-active-bg: #{$stepper-item-active-bg};
  --stepper-inactive-bg: #{$stepper-item-inactive-bd};
  --stepper-inactive-bd: #{$stepper-item-inactive-bd};
  --stepper-active-bd: #{$stepper-item-active-bd};
  --stepper-inactive-color: #{$stepper-item-inactive-color};
  --stepper-active-color: #{$stepper-item-active-color};
  --stepper-selected-color: #{$stepper-item-selected-color};
  justify-content: space-between;
  align-items: flex-end;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  .step-wrapper {
    cursor: default;
    align-items: flex-end;
    flex-grow: 1;
    position: relative;

    .step {
      min-width: 100px;
      z-index: 1;
    }

    .bullet {
      font-size: 14px;
      min-width: 16px;
      width: 16px;
      height: 16px;
      text-align: center;
      color: #666d92;
      background-color: #666d92;
      border-radius: 50%;
      border: 5px solid #666d92;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -1rem !important;
      position: relative;
      &:before {
        content: "";
        width: 16px;
        height: 16px;
        position: absolute;
        background: var(--stepper-active-bd);
        transition: transform 0.2s linear, opacity 0.2s linear;
        border-radius: 50%;
        transform: scale(0);
        opacity: 0;
        z-index: 2;
      }
      &:after {
        content: "";
        width: 8px;
        height: 8px;
        background: white;
        transition: transform 0.2s linear, opacity 0.2s linear;
        border-radius: 50%;
        position: absolute;
        transform: scale(0);
        opacity: 0;
        z-index: 3;
      }
    }

    &:first-child .bullet {
      margin-left: 0px;
    }

    .bullet-label {
      color: var(--c, var(--stepper-inactive-color));
      color: #666d92;
      font-family: $MontserratSemiBold;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 115%;
      letter-spacing: -0.14px;
    }

    .separator {
      border-top: 4px solid #666d92;
      margin-bottom: calc((15px / 2) - (3px / 2));
      height: inherit;
      width: 100%;
      position: absolute;
      left: -99%;
      bottom: 0;
      z-index: 1;
      &:before {
        content: "";
        width: 0;
        height: 4px;
        position: absolute;
        background: var(--stepper-active-bd);
        bottom: 0;
        transition: width 0.2s linear;
      }
    }

    &.step-selectable {
      cursor: pointer;

      .bullet {
        --bd: var(--stepper-inactive-bd);
        --bg: var(--stepper-inactive-bg);
        --c: var(--stepper-active-color);
      }

      .bullet-label {
        color: #0000ff;
      }
    }

    &.step-selected {
      .bullet {
        &:before {
          transform: scale(1);
          transition: transform 0.2s linear, opacity 0.2s linear;
          transition-delay: 0.2s;
          opacity: 1;
        }
        &:after {
          transform: scale(1);
          transition: transform 0.2s linear, opacity 0.2s linear;
          transition-delay: 0.2s;
          opacity: 1;
        }
      }

      .separator {
        &:before {
          transition: width 0.2s linear;
          width: 100%;
        }
      }

      .bullet-label {
        transition: color 0.2s linear;
        color: #0000ff;
        transition-delay: 0.2s;
      }
    }

    &.step-completed {
      cursor: pointer;

      .bullet {
        --bd: var(--stepper-active-bd);
        --bg: var(--stepper-active-bg);
        &:before {
          transform: scale(1);
          transition: transform 0.2s linear, opacity 0.2s linear;
          transition-delay: 0.2s;
          opacity: 1;
        }
        &:after {
          transform: scale(0);
          transition: transform 0.2s linear, opacity 0.2s linear;
          transition-delay: 0.2s;
          opacity: 0;
        }
      }

      .bullet-label {
        transition: color 0.2s linear;
        color: #0000ff;
      }
      .separator {
        --bd: var(--stepper-active-bd);
        &:before {
          transition: width 0.2s linear;
          width: 100%;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .bullet {
      margin-right: 8px;
    }

    .bullet-label {
      padding: 0;
      font-size: 14px !important;
    }
  }
}
.title-stepper {
  font-size: 28px;
  font-family: $MontserratSemiBold;
  font-weight: 600 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  height: 40px;
}
