//separator
div[class^="separator-text"],
span[class^="separator-text"] {
  width: $separator-width;
  height: $separator-height-md;
  margin: 0 $separator-margin-x-md;
  display: inline-block;
  vertical-align: middle;
  &.separator-sm {
    height: $separator-height-sm;
    margin: 0 $separator-margin-x-sm;
  }
  &.separator-lg {
    height: $separator-height-lg;
    margin: 0 $separator-margin-x-lg;
  }
}
