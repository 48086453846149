// title custom font
h1,
h2,
h3,
.h1,
.h2,
.h3 {
  font-family: $headings-lg-font-family;
  font-weight: $headings-lg-font-weight !important;
}
h4,
h5,
.h4,
.h5 {
  font-family: $headings-sm-font-family;
  font-weight: $headings-sm-font-weight !important;
}

// subtitles custom classes
*[class*="subtitle-"] {
  font-family: $subtitle-font-family;
  color: $subtitle-color;
}
.subtitle-lg {
  font-size: $subtitle-lg-font-size;
}
.subtitle-sm {
  font-size: $subtitle-sm-font-size;
}

// paragraph
p.subtitle-lg {
  font-size: $subtitle-lg-font-size;
  color: $subtitle-color;
}
p.subtitle-sm {
  font-size: $subtitle-sm-font-size;
  color: $subtitle-color;
}
