// mixins Docaposte

/* generation de classes pour le font-size converti de px a rem */
@mixin fs-variant($parent, $fs) {
  #{$parent} {
    font-size: calc($fs / $font-size-context) rem !important;
    line-height: calc($fs/11.25) rem;
  }
}
/* generation de classes pour tronquer le texte dans un nombre spécifique des lignes. */
@mixin line-variant($parent, $line) {
  #{$parent} {
    display: -webkit-box;
    -webkit-line-clamp: #{$line};
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
