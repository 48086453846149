//btn variant
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @if $color == "white" {
      @include button-variant($value, $value, $digital, null, $digital);
    } @else {
      @include button-variant($value, $value);
    }
  }
}

//outline btn primary
@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline($value);
  }
}
//outline btn primary
@each $color, $value in $theme-colors {
  .btn-ghost-#{$color} {
    @include button-ghost-($value);
  }
}
