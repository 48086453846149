// font size classes
@each $size, $value in $font-sizes {
  @include fs-variant(".fs-#{$size}", $value);
}
// line truncat multiline
@each $line, $value in $lines-clamp {
  @include line-variant(".line-#{$line}", $value);
}
//line-1 ellipsis
.line-1 {
  @include text-truncate;
}
