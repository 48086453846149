.file-upload {
  ngx-dropzone-label {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    margin: 0 !important;
    text-align: initial !important;
    align-items: center;
  }

  .dropzone {
    border-radius: 8px;
    border: 2px dashed var(--Action-Cobalt-100, #00f);
    background: var(--Background-Light, #f6f6f8);
    min-height: 142px;
    height: auto !important;
    padding: 32px;

    &.ngx-dz-hovered {
      background-color: #bbbbef !important;
    }
  }
}
.file-upload-list {
  .files {
    border: 1px solid $gray-100;
    border-radius: $border-radius;
    &:not(:last-child) {
      border-bottom: 1px solid $gray-200;
    }
    .position-file {
      border-right: 1px solid $gray-100;
    }
    .icon-file {
      flex: 0 0 22px;
    }

    .name {
      flex: 1 0 160px;
    }

    .date {
      flex: 1 0 auto;
    }

    .size {
      flex: 1 0 80px;
    }

    .icon-trash {
      flex: 0 0 22px;
      cursor: pointer;
    }

    &:focus {
      //outline: none;
    }
    .dropdown-toggle {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  img {
    display: none;
  }
}
@include media-breakpoint-down(sm) {
  .file-upload {
    ngx-dropzone-label {
      flex-direction: column;
      text-align: center !important;
    }
  }
  .file-upload-list {
    .name {
      font-size: 0.9375rem;
    }
  }
}
@include media-breakpoint-down(xs) {
  .file-upload {
    .dropzone {
      border: none !important;
      background: $white !important;
    }
  }
}
