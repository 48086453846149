// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

//tooltip add custom class bootstrap
//white-tooltip
@import "themes/_docaposte-variables.scss";
.bg-white-tooltip {
  .tooltip-inner {
    background: $white;
    color: $lynch;
    box-shadow: $card-box-shadow;
  }

  &.bs-tooltip-top .arrow::before {
    border-top-color: $white;
  }
  &.bs-tooltip-left .arrow::before {
    border-left-color: $white;
  }
  &.bs-tooltip-bottom .arrow::before {
    border-bottom-color: $white;
  }
  &.bs-tooltip-right .arrow::before {
    border-right-color: $white;
  }
  .arrow::after {
    content: "";
    position: absolute;
    z-index: -1;
    border: 5px solid #fff;
    transform-origin: 0 0;
    transform: rotate(45deg);
    box-shadow: $card-box-shadow;
  }
  &.bs-tooltip-right .arrow::after {
    left: 100%;
    top: 0;
  }
  &.bs-tooltip-left .arrow::after {
    left: 0;
    top: 0;
  }
  &.bs-tooltip-bottom .arrow::after {
    left: 50%;
    top: 100%;
  }
  &.bs-tooltip-top .arrow::after {
    left: 50%;
    bottom: 100%;
  }
}
.bg-red-tooltip {
  .tooltip-inner {
    background: $red-tool;
    color: $white;
    box-shadow: $card-box-shadow;
  }

  &.bs-tooltip-top .arrow::before {
    border-top-color: $red-tool;
  }
  &.bs-tooltip-left .arrow::before {
    border-left-color: $red-tool;
  }
  &.bs-tooltip-bottom .arrow::before {
    border-bottom-color: $red-tool;
  }
  &.bs-tooltip-right .arrow::before {
    border-right-color: $red-tool;
  }
  .arrow::after {
    content: "";
    position: absolute;
    z-index: -1;
    border: 5px solid #fff;
    transform-origin: 0 0;
    transform: rotate(45deg);
    box-shadow: $card-box-shadow;
  }
  &.bs-tooltip-right .arrow::after {
    left: 100%;
    top: 0;
  }
  &.bs-tooltip-left .arrow::after {
    left: 0;
    top: 0;
  }
  &.bs-tooltip-bottom .arrow::after {
    left: 50%;
    top: 100%;
  }
  &.bs-tooltip-top .arrow::after {
    left: 50%;
    bottom: 100%;
  }
}

.bg-gril-tooltip {
  .tooltip-inner {
    background: $grill-tool;
    color: $white;
    box-shadow: $card-box-shadow;
  }

  &.bs-tooltip-top .arrow::before {
    border-top-color: $grill-tool;
  }
  &.bs-tooltip-left .arrow::before {
    border-left-color: $grill-tool;
  }
  &.bs-tooltip-bottom .arrow::before {
    border-bottom-color: $grill-tool;
  }
  &.bs-tooltip-right .arrow::before {
    border-right-color: $grill-tool;
  }
  .arrow::after {
    content: "";
    position: absolute;
    z-index: -1;
    border: 5px solid #fff;
    transform-origin: 0 0;
    transform: rotate(45deg);
    box-shadow: $card-box-shadow;
  }
  &.bs-tooltip-right .arrow::after {
    left: 100%;
    top: 0;
  }
  &.bs-tooltip-left .arrow::after {
    left: 0;
    top: 0;
  }
  &.bs-tooltip-bottom .arrow::after {
    left: 50%;
    top: 100%;
  }
  &.bs-tooltip-top .arrow::after {
    left: 50%;
    bottom: 100%;
  }
}
.bg-vert-tooltip {
  .tooltip-inner {
    background: $vert-tool;
    color: $white;
    box-shadow: $card-box-shadow;
  }

  &.bs-tooltip-top .arrow::before {
    border-top-color: $vert-tool;
  }
  &.bs-tooltip-left .arrow::before {
    border-left-color: $vert-tool;
  }
  &.bs-tooltip-bottom .arrow::before {
    border-bottom-color: $vert-tool;
  }
  &.bs-tooltip-right .arrow::before {
    border-right-color: $vert-tool;
  }
  .arrow::after {
    content: "";
    position: absolute;
    z-index: -1;
    border: 5px solid #fff;
    transform-origin: 0 0;
    transform: rotate(45deg);
    box-shadow: $card-box-shadow;
  }
  &.bs-tooltip-right .arrow::after {
    left: 100%;
    top: 0;
  }
  &.bs-tooltip-left .arrow::after {
    left: 0;
    top: 0;
  }
  &.bs-tooltip-bottom .arrow::after {
    left: 50%;
    top: 100%;
  }
  &.bs-tooltip-top .arrow::after {
    left: 50%;
    bottom: 100%;
  }
}

.bg-bleu-tooltip {
  .tooltip-inner {
    background: $bleu-tool;
    color: $white;
    box-shadow: $card-box-shadow;
  }

  &.bs-tooltip-top .arrow::before {
    border-top-color: $bleu-tool;
  }
  &.bs-tooltip-left .arrow::before {
    border-left-color: $bleu-tool;
  }
  &.bs-tooltip-bottom .arrow::before {
    border-bottom-color: $bleu-tool;
  }
  &.bs-tooltip-right .arrow::before {
    border-right-color: $bleu-tool;
  }
  .arrow::after {
    content: "";
    position: absolute;
    z-index: -1;
    border: 5px solid #fff;
    transform-origin: 0 0;
    transform: rotate(45deg);
    box-shadow: $card-box-shadow;
  }
  &.bs-tooltip-right .arrow::after {
    left: 100%;
    top: 0;
  }
  &.bs-tooltip-left .arrow::after {
    left: 0;
    top: 0;
  }
  &.bs-tooltip-bottom .arrow::after {
    left: 50%;
    top: 100%;
  }
  &.bs-tooltip-top .arrow::after {
    left: 50%;
    bottom: 100%;
  }
}

.icon-info-fill::before {
  color: $info;
}

.tooltip-inner {
  min-width: 17rem;
  border-radius: 0.5rem;
  padding: 1rem 1.25rem 1rem 1.25rem;
  align-items: flex-start;
  text-align: start;
}
