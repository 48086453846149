* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.global {
  width: 100%;
  max-width: 120rem;
  overflow: hidden;
  position: relative;
  padding-bottom: 3.125rem;
  min-height: 100vh;
  margin: 0 auto;
}
.container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 0 1.5625rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
body {
  font-family: $Montserrat;
  font-weight: 400;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  background: #fff;
}
li {
  list-style: none;
}
a {
  -webkit-transition: all 0.3s ease-in;
  -o-transition: 0.3s all ease-in;
  -moz-transition: 0.3s all ease-in;
  transition: all 0.3s ease-in;
  outline: none;
  cursor: pointer;
  display: inline-block;
}
a:hover {
  text-decoration: none !important;
}
img {
  max-width: 100%;
  height: auto;
}
p {
  font-size: 1rem;
}
strong {
  font-weight: 700;
}
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}
a {
  text-decoration: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button,
input,
textarea {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
input,
select,
textarea {
  outline: none;
}
button,
input,
input[type="submit"],
select,
textarea {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -moz-border-radius: 0;
  border-radius: 0;
}
a,
button,
input {
  outline: none;
}
a[href^="tel:"] {
  color: inherit;
  text-decoration: none;
}
.fullWidth {
  width: 100% !important;
}
.flex {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 30px;
}
.flex .item {
  -webkit-flex-basis: 33.33333%;
  -ms-flex-preferred-size: 33.33333%;
  flex-basis: 33.33333%;
  min-height: 300px;
}
.space-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.align-items {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.right {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.column {
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
}
.column,
.row {
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
}
.row {
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.font-barlow-medium {
  font-family: $BarlowMedium !important;
  font-weight: 500 !important;
}
.mgt20 {
  margin-top: 1.25rem;
}
hr {
  opacity: 0.5;
}
.pd20 {
  padding: 0 1.875rem;
}
.pdb60 {
  padding-bottom: 3.75rem !important;
}
.mgr20 {
  margin-right: 1.25rem;
}
.mgr30 {
  margin-right: 1.875rem;
}
.mgr40 {
  margin-right: 2.5rem;
}
.mgl20 {
  margin-left: 1.25rem;
}
.mgl30 {
  margin-left: 1.875rem;
}
.mgl40 {
  margin-left: 2.5rem;
}
.mgb10 {
  margin-bottom: 0.625rem;
}
.mgb20 {
  margin-bottom: 1.25rem;
}
.mgb30 {
  margin-bottom: 1.875rem;
}
.mgb40 {
  margin-bottom: 2.5rem;
}
.mgb50 {
  margin-bottom: 3.125rem;
}
.relative {
  position: relative;
}
*,
:focus {
  outline: none !important;
}

.display-block {
  display: block;
}

ngb-datepicker {
  padding: 5px 20px;
  border: none !important;
}
.ngb-dp-header,
.ngb-dp-month-name,
.ngb-dp-week.ngb-dp-weekdays,
ngb-datepicker {
  background: #fff !important;
}
.ngb-dp-header {
  margin-bottom: 10px;
}
.ngb-dp-week.ngb-dp-weekdays {
  -moz-border-radius-topleft: 5px;
  border-top-left-radius: 5px;
  -moz-border-radius-topright: 5px;
  border-top-right-radius: 5px;
}
.ngb-dp-week.ngb-dp-weekdays:after {
  display: none !important;
}
.ngb-dp-weekdays {
  border-bottom: none !important;
}
.ngb-dp-weekday {
  font-style: normal !important;
  color: #00008c !important;
  font-size: 14px;
  font-family: $BarlowBold !important;
  font-weight: 700;
}
ngb-datepicker-month {
  background: #f8f8fb !important;
  border: 1px solid #dadbe0 !important;
  position: relative;
  z-index: 1;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.ngb-dp-month:first-child {
  margin-right: 10px;
}
.ngb-dp-day .custom-day {
  font-family: $Barlow !important;
  font-weight: 400 !important;
  color: #666d92 !important;
  font-size: 14px !important;
  height: 1.5rem !important;
  width: 1.5rem;
}
.ngb-dp-day,
.ngb-dp-week-number,
.ngb-dp-weekday {
  height: 1.5rem !important;
  width: 2rem !important;
}
.ngb-dp-day .custom-day.range,
.ngb-dp-day .custom-day:hover {
  color: #fff !important;
}
.ngb-dp-month-name {
  color: #00008c;
  font-family: $MontserratSemiBold !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}
.ngb-dp-navigation-select .custom-select:first-child {
  margin-right: 10px;
}
.ngb-dp-navigation-select .custom-select:last-child {
  margin-left: 10px;
}
.ngx-datatable {
  /* -moz-border-radius: 8px;
  border-radius: 8px; */
  -webkit-box-shadow: 0 5px 10px #cccedc;
  -moz-box-shadow: 0 5px 10px #cccedc;
  box-shadow: 0 5px 10px #cccedc;
  margin-bottom: 80px;
}
.datatable-header {
  border-bottom: none !important;
}
.datatable-header .datatable-row-center {
  background: transparent !important;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 5px;
  width: 100% !important;
}
.datatable-header-cell-label,
.dropdown .form-control,
.grid-dropdown .form-control {
  font-family: $BarlowMedium;
  font-weight: 500;
  font-size: 14px;
}
.datatable-header-cell-label {
  color: #666d92;
  font-weight: 500 !important;
}
.ngx-datatable .datatable-body-cell-label span {
  font-family: $Barlow;
  font-size: 14px;
  color: #666d92;
  font-weight: 500 !important;
}
.mainTitle {
  font-family: $MontserratSemiBold;
  font-weight: 600 !important;
  font-size: 32px;
  line-height: 36px;
  color: #00008c;
}
@media screen and (max-width: 600px) {
  .wrapper-mainTitle {
    width: 100%;
  }
  .wrapper-mainTitle + div {
    width: 100%;
  }
  .wrapper-mainTitle + div .btn.btn-primary {
    width: 100%;
  }
  .wrapper-mainTitle + div .dropdownSel3_wrapper {
    width: 90%;
    margin-left: 5px;
  }
  .mainTitle {
    font-size: 24px;
    text-align: center;
  }
  .mainBtn {
    font-size: 0 !important;
    height: 30px;
    line-height: 3px;
    width: 100%;
  }
  .mainBtn i {
    font-size: 20px !important;
    position: relative;
    top: -9px;
  }
}
.widthModal {
  width: 1000px;
}
.widthModal8 {
  width: 850px;
  margin-left: -50px;
  border-radius: 10px;
}
.widthModal8 .ngx-datatable {
  margin-bottom: 20px;
}
.widthModal8 .modal-body {
  background: #ffffff;
  border-radius: 0 0 10px 10px;
  min-height: 300px;
}
@media screen and (max-width: 1025px) {
  .widthModal {
    max-width: 100%;
    width: 900px;
  }
  .widthModal8 {
    max-width: 100%;
    width: 1000px;
    margin-left: 0;
  }
  .widthModal8 .dropdownSel3 {
    width: 240px !important;
    left: -50px !important;
  }
}
@media screen and (max-width: 560px) {
  .widthModal8 .app-input {
    margin-bottom: 20px;
  }
  .widthModal8 .dropdownSel3 {
    width: 100% !important;
    left: 0 !important;
  }
}
@media screen and (max-width: 769px) and (min-width: 450px) {
  .widthModal .nav-pills {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 250px;
    -moz-box-flex: 0;
    -ms-flex: 0 0 250px;
    flex: 0 0 250px;
  }
  .widthModal .nav-pills li a {
    font-size: 14px;
  }
}
.widthModal .modal-footer,
.widthModal .tab-content {
  width: 100%;
  background: #fff;
}
.heightModal {
  max-height: 420px !important;
  height: 100% !important;
}
@media screen and (max-width: 500px) {
  .footer > div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .footer > div a {
    margin-bottom: 10px;
  }
  .footer .ico-dropdown {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .footer div.justify-content-end img {
    display: none;
  }
}
@media screen and (max-width: 769px) and (min-width: 450px) {
  .m-logo img {
    width: 20% !important;
  }
}
.ngx-datatable .datatable-body-cell-label span.badge-info {
  color: #fff;
}
@media screen and (max-width: 1100px) and (min-width: 990px) {
  #header .btn {
    margin-right: 0 !important;
  }
  #header .btn span {
    font-size: 12px !important;
  }
  #header .btn i {
    font-size: 16px !important;
  }
  .btn-reset:hover:after {
    width: 0;
  }
}
.currentLink {
  color: #00f !important;
  background-color: #f5f5ff !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ng2-pdf-viewer-container {
  width: 95% !important;
  height: 85% !important;
}
.grid-dropdown.openDropdown .dropdownSel3 {
  display: block;
}

.dropdownSel3 {
  max-width: 21.25rem;
  width: 100%;
  background: #fff;
  -moz-border-radius: 0.625rem;
  border-radius: 0.625rem;
  display: inline-block;
  vertical-align: text-top;
  -webkit-box-shadow: 0 3px 10px rgba(204, 206, 220, 0.8);
  -moz-box-shadow: 0 3px 10px rgba(204, 206, 220, 0.8);
  box-shadow: 0 3px 10px rgba(204, 206, 220, 0.8);
}
.dropdownSel3_search {
  padding: 0.9375rem 1.5625rem;
  border-bottom: 0.0625rem solid #cccedc;
}
.dropdownSel3_search--field {
  -moz-border-radius: 0.625rem;
  border-radius: 0.625rem;
  border: 0.0625rem solid #ececef;
  height: 2.6rem;
  position: relative;
}
.dropdownSel3_search--field:before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0.9375rem;
  font-size: 1.75rem;
  font-family: Material-Design-Iconic-Font;
  color: #00008c;
  background: url(/assets/img/icons/search.svg) no-repeat scroll;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100%;
  background-size: 100%;
  width: 1.5rem;
  height: 1.5rem;
}
.dropdownSel3_search--field input {
  border: none;
  background: transparent;
  display: block;
  width: 100%;
  height: 100%;
  font-family: $BarlowBold;
  font-weight: 700;
  font-size: 0.875rem;
  color: #666d92;
  padding-left: 3.3125rem;
}
.dropdownSel3_search--field input::-webkit-input-placeholder {
  font-family: $Barlow;
  font-weight: 400;
}
.dropdownSel3_search--field input::-moz-placeholder {
  font-family: $Barlow;
  font-weight: 400;
}
.dropdownSel3_search--field input:-ms-input-placeholder {
  font-family: $Barlow;
  font-weight: 400;
}
.dropdownSel3_search--field input::placeholder {
  font-family: $Barlow;
  font-weight: 400;
}
.dropdownSel3_item {
  position: relative;
  border-bottom: 0.0625rem solid rgba(204, 206, 220, 0.5);
}
.dropdownSel3_item:last-child {
  border-bottom: none;
}
.dropdownSel3_item .arrow {
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  width: 1.5rem;
  height: 1.25rem;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  cursor: pointer;
}
.dropdownSel3_item.current .arrow {
  top: 35%;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.dropdownSel3_item .checkTitle {
  display: inline-block;
}
.dropdownSel3_item .checkTitle:last-child {
  border-bottom: none;
}
.dropdownSel3_item .checkField {
  color: #00008c;
  font-family: $BarlowBold;
  font-weight: 700;
  font-size: 0.875rem;
}
.dropdownSel3_item .checkFieldR {
  color: #00008c;
  font-family: $BarlowBold;
  font-weight: 700;
  font-size: 0.875rem;
}
.dropdownSel3_item .checkField:after,
.dropdownSel3_item .checkField:before {
  left: 0.9375rem;
}
.dropdownSel3_item .checkFieldR:after,
.dropdownSel3_item .checkFieldR:before {
  left: 0.9375rem;
}
.dropdownSel3_fields {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  display: none;
}
.dropdownSel3_fields li {
  padding-left: 1.875rem;
}
.dropdownSel3_fields li:first-child {
  border-top: 0.0625rem solid rgba(204, 206, 220, 0.5);
}
.dropdownSel3_fields li:not(:last-child) {
  border-bottom: 0.0625rem solid rgba(204, 206, 220, 0.5);
}
input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked + .checkField:after {
  opacity: 1;
}

.checkField {
  display: inline-block;
  font-size: 0.875rem !important;
  font-family: $BarlowMedium !important;
  font-weight: 500 !important;
  color: #00008c;
  padding: 0.9375rem 1.5625rem 0.9375rem 2.625rem;
  position: relative;
  cursor: pointer;
}
.checkFieldR {
  display: inline-block;
  font-size: 0.875rem !important;
  font-family: $BarlowMedium !important;
  font-weight: 500 !important;
  color: #00008c;
  padding: 0.9375rem 1.5625rem 0.9375rem 2.625rem;
  position: relative;
  cursor: pointer;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: var(--Text-Ultramarine, #00008c);
  font-feature-settings:
    "clig" off,
    "liga" off;
  /* Subtitle/Subtitle 2 */
  font-family: $Barlow;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  border-bottom: 1px solid #ededf2;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #666d92;
  font-family: $BarlowMedium !important;
  font-weight: 400 !important;
  line-height: 16.8px;
}

.ng-select.ng-select-single .ng-select-container {
  padding: 0px !important;
}

.ng-dropdown-panel.ng-select-bottom {
  top: 105%;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 12px 24px 0px rgb(102 109 146 / 20%);
}

.ng-select-opened .ng-select-container {
  border: 1px solid var(--Action-Selected, #00c) !important;
  background: var(--Background-White, #fff) !important;
  border-radius: 8px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label {
  background: var(--Action-Cobalt-10, #e5e5fd) !important;
  color: #001eae !important;
  font-weight: 500;
}

.ng-option:hover {
  background: var(--Action-Cobalt-05, #f2f2ff) !important;
}

.ng-select .ng-select-container:hover {
  border: 1px solid var(--Action-Cobalt-10, #e5e5fd) !important;
  background: var(--Action-Cobalt-05, #f2f2ff) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:first-child {
  border-radius: 8px 8px 0 0 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:last-child {
  border-radius: 0 0 8px 8px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  color: #00008c !important;
}
.ng-select .ng-select-container {
  width: 780px;
  height: 40px !important;
  padding: 11.5px 0px 11.5px 16px !important;
  border-radius: 8px;
  border: 1px solid#EDEDF2;
  gap: 12px;
  background-color: #ffffff;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  font-size: 0.875rem !important;
  font-family: $BarlowMedium !important;
  font-weight: 500 !important;
  color: #00008c;
}
.checkField:before {
  content: "";
  border: 0.0625rem solid #666d92;
  background: #f8f8fb;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.checkFieldR:before {
  content: "";
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 0.0625rem solid #666d92;
  background: #f8f8fb;
  /*-moz-border-radius: 4px;
  border-radius: 4px;*/
}
.checkField:after,
.checkField:before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0.9375rem;
  height: 16px;
  width: 16px;
}
.checkFieldR:after,
.checkFieldR:before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0.9375rem;
  height: 16px;
  width: 16px;
}
.checkField:after {
  content: "\f26b";
  background: #3363cc;
  border: 0.0625rem solid #00f;
  -moz-border-radius: 0.3125rem;
  border-radius: 0.3125rem;
  font-family: Material-Design-Iconic-Font;
  font-size: 0.875rem;
  text-align: center;
  line-height: 0.75rem;
  color: #fff;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.checkFieldR:after {
  content: "";
  border: 5px solid #3970e6 !important;
  background: white;
  border-radius: 20px;
  /*border: 0.0625rem solid #00f;
  -moz-border-radius: 0.3125rem;
  border-radius: 0.3125rem;*/
  font-family: Material-Design-Iconic-Font;
  font-size: 0.875rem;
  text-align: center;
  line-height: 0.75rem;
  color: #fff;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.dropdownSimple {
  max-width: 23.5625rem;
  -webkit-box-shadow: 0 3px 10px rgba(204, 206, 220, 0.8);
  -moz-box-shadow: 0 3px 10px rgba(204, 206, 220, 0.8);
  box-shadow: 0 3px 10px rgba(204, 206, 220, 0.8);
  padding: 0 0.9375rem;
}
.dropdownSimple_item {
  padding-bottom: 0.9375rem;
}
.dropdownSimple .checkField {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1.875rem;
}
.dropdownSimple .checkField:after,
.dropdownSimple .checkField:before {
  left: 0;
}
.dropdownSimple .flex {
  padding: 0.9375rem 0.9375rem 1.5rem;
}
.dropdownSimple .dropdownSel3_search {
  border-bottom: none;
  padding-bottom: 1.5625rem;
  padding-left: 0;
  padding-right: 0;
}
.border {
  border-bottom: 0.0625rem solid rgba(204, 206, 220, 0.5);
}
.open .dropdownSel3_fields {
  display: block;
}
.open .arrow {
  top: 0.9375rem;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.grid-dropdown {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.grid-dropdown .btn-blue {
  position: relative;
  z-index: 2;
}
.grid-dropdown .dropdownSel3 {
  position: absolute;
  left: 0;
  top: 50px;
  z-index: 1;
  /* -moz-border-radius-topleft: 0;
  border-top-left-radius: 0; */
  display: none;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.grid-dropdown.open .dropdownSel3 {
  display: block;
}
.widthSearch {
  width: 250px;
}
.dpdFile,
.dpdvalue {
  width: 100%;
  background: #fff;
  -moz-border-radius: 0.625rem;
  border-radius: 0.625rem;
  position: relative;
}
.dpdFile .dropdownSel3_search--field,
.dpdvalue .dropdownSel3_search--field {
  border: 1px solid #dadbe0;
  position: relative;
  line-height: 2.1875rem;
}
.dpdFile .dropdownSel3_search--field:before,
.dpdvalue .dropdownSel3_search--field:before {
  display: none;
}
.dpdFile .dropdownSel3_search--field .valDbpFile,
.dpdFile .dropdownSel3_search--field input,
.dpdvalue .dropdownSel3_search--field .valDbpFile,
.dpdvalue .dropdownSel3_search--field input {
  padding-left: 1.25rem;
  font-family: $BarlowMedium;
  font-weight: 500;
  font-size: 0.875rem;
  color: #00008c;
  padding-right: 1.875rem;
}
.dpdFile .dropdownSel3_search--field .valDbpFile,
.dpdvalue .dropdownSel3_search--field .valDbpFile {
  cursor: pointer;
}
.dpdFile .dropdownSel3_search--field .arrow,
.dpdvalue .dropdownSel3_search--field .arrow {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  /* background: green; */
}
.dpdFile .dropdownSel3_search--field .arrow img,
.dpdvalue .dropdownSel3_search--field .arrow img {
  position: absolute;
  right: 10px;
  top: 10px;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.dpdFile .dropdownSimple_item,
.dpdvalue .dropdownSimple_item {
  padding-top: 0;
  padding-bottom: 0;
}
.dpdFile .dropdownSel3_wrapper,
.dpdvalue .dropdownSel3_wrapper {
  display: none;
  position: absolute;
  background: #fff;
  -webkit-box-shadow: 0 3px 10px rgba(204, 206, 220, 0.8);
  -moz-box-shadow: 0 3px 10px rgba(204, 206, 220, 0.8);
  box-shadow: 0 3px 10px rgba(204, 206, 220, 0.8);
  z-index: 3;
  width: 100%;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.dpdFile .dropdownSimple_item--add label,
.dpdFile .dropdownSimple_item--add label input,
.dpdvalue .dropdownSimple_item--add label,
.dpdvalue .dropdownSimple_item--add label input {
  width: 100%;
}
.dpdFile.current,
.dpdvalue.current {
  -webkit-box-shadow: 0 3px 10px rgba(204, 206, 220, 0.8);
  -moz-box-shadow: 0 3px 10px rgba(204, 206, 220, 0.8);
  box-shadow: 0 3px 10px rgba(204, 206, 220, 0.8);
}
.dpdFile.current .dropdownSel3_search--field .arrow img,
.dpdvalue.current .dropdownSel3_search--field .arrow img {
  top: 10px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.dpdFile.current .dropdownSel3_wrapper,
.dpdvalue.current .dropdownSel3_wrapper {
  display: block;
}
.dpdFile .dropdownSimple_item {
  border-bottom: 0.0625rem solid #cccedc;
}
.dpdFile .dropdownSimple_item:last-child {
  border-bottom: none;
}
.dpdFile .dropdownSimple_item .checkField {
  font-size: 0.875rem;
  font-family: $BarlowBold !important;
  font-weight: 700 !important;
  color: #00008c !important;
}
.dpdFile .dropdownSimple_item .checkField input {
  border: 0.0625rem solid #dadbe0;
  -moz-border-radius: 0.625rem;
  border-radius: 0.625rem;
  height: 2.5rem;
  padding: 0 0.625rem;
  font-size: 0.875rem;
  font-family: $BarlowMedium !important;
  font-weight: 500 !important;
  color: #00008c !important;
}
.tag {
  display: inline-block;
  background: #3970e6;
  color: #fff;
  font-family: $Barlow;
  font-weight: 400;
  font-size: 0.75rem;
  padding: 0.1875rem 0.625rem 0.3125rem;
  -moz-border-radius: 0.3125rem;
  border-radius: 0.3125rem;
}
.tag .delete {
  color: #fff;
  margin-left: 5px;
  font-size: 14px;
  cursor: pointer;
}
.dpdcountries .dropdownSel3_wrapper {
  padding: 0.9375rem 0;
}
.dpdcountries .dropdownSimple_item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0.9375rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.dpdcountries .dropdownSimple_item .flag {
  border: 1px solid #e5e5fd;
  padding: 3px 5px 2px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  height: 24px;
  line-height: 12px;
  position: relative;
  top: -5px;
}
.dpdcountries .dropdownSimple_item .country {
  margin-left: 0.625rem;
  font-family: $Barlow;
  font-weight: 400;
  font-size: 14px;
  color: #666d92;
  margin-bottom: 10px;
}
.accordian {
  max-width: 60.3125rem;
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 0.0625rem 0.625rem rgba(0, 0, 46, 0.06);
  -moz-box-shadow: 0 0.0625rem 0.625rem rgba(0, 0, 46, 0.06);
  box-shadow: 0 0.0625rem 0.625rem rgba(0, 0, 46, 0.06);
  margin: 0 auto;
  padding: 1.875rem;
  -moz-border-radius: 0.625rem;
  border-radius: 0.625rem;
  margin-bottom: 1.75rem;
}
@media screen and (max-width: 769px) {
  .accordian {
    max-width: 100%;
    width: 90%;
  }
}
.accordian_header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.accordian_header--title {
  font-family: $BarlowMedium;
  font-weight: 500;
  color: #00008c;
  font-size: 1.25rem;
  padding-left: 2.5rem;
  position: relative;
}
@media screen and (max-width: 769px) {
  .accordian_header--title {
    font-size: 1.125rem;
  }
}
.accordian_header--title:after {
  content: "";
  position: absolute;
  top: -0.3125rem;
  left: -0.3125rem;
  color: #00008c;
  font-size: 1.375rem;
  width: 2rem;
  height: 2rem;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
}
.accordian_header--title.information:after {
  background: url(/assets/img/icons/profil.svg) no-repeat scroll 50%;
  -webkit-background-size: 80% 80%;
  -moz-background-size: 80%;
  background-size: 80%;
}
.actionsProfile:first-child {
  position: relative;
}
.actionsProfile:first-child:before {
  content: "";
  background: url(/assets/img/icons/profil.svg) no-repeat scroll 50%;
  -webkit-background-size: 80% 80%;
  -moz-background-size: 80%;
  background-size: 80%;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 25px;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
}
.accordian_header--title.sign:after {
  background: url(/assets/img/icons/sign-2.svg) no-repeat scroll 50%;
  -webkit-background-size: 90% 90%;
  -moz-background-size: 90%;
  background-size: 90%;
}
.accordian_header--title.notification:after {
  background: url(/assets/img/icons/notification.svg) no-repeat scroll 50%;
  -webkit-background-size: 80% 80%;
  -moz-background-size: 80%;
  background-size: 80%;
}
.accordian_header--title.ratio:after {
  background: url(/assets/img/icons/ratio.svg) no-repeat scroll 50%;
  -webkit-background-size: 70% 70%;
  -moz-background-size: 70%;
  background-size: 70%;
}
.accordian_header--title.lock:after {
  background: url(/assets/img/icons/lock.svg) no-repeat scroll 50%;
  -webkit-background-size: 70% 70%;
  -moz-background-size: 70%;
  background-size: 70%;
}
.accordian_header--arrow {
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.accordian_header--arrow img {
  display: block;
  width: 1.875rem;
}
.accordian_body {
  display: none;
  min-height: 6.25rem;
  padding-top: 1.875rem;
  border-top: 0.0625rem solid #dadbe0;
  margin-top: 1.875rem;
}
.accordian.active .accordian_body {
  display: block;
}
.accordian.active .accordian_header--arrow {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.icon-info {
  width: 1.875rem;
  height: 1.875rem;
  background: url(/assets/img/icons/warning.svg) no-repeat scroll;
  cursor: pointer;
  display: none;
}
.infoBulle {
  max-width: 31.25rem;
  width: 100%;
  background: #3970e6;
  -moz-border-radius: 0.625rem;
  border-radius: 0.625rem;
  padding: 0.9375rem;
  color: #fff;
  font-family: $Barlow;
  font-weight: 400;
  font-size: 0.875rem;
  position: relative;
  display: none;
}
.infoBulle:after {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -0.625rem;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #3970e6;
}
.infoBulle p {
  padding-bottom: 1.25rem;
}
.infoBulle ul {
  padding-bottom: 0.9375rem;
}
.infoBulle ul li {
  padding-bottom: 0.3125rem;
  position: relative;
}
.infoBulle ul li:after {
  content: "";
}
.infoBulle ul li strong {
  font-family: $BarlowBold;
  font-weight: 700;
}
.infoBulle a {
  color: #fff;
  margin-bottom: 1.25rem;
  font-family: $Montserrat;
  font-weight: 400;
  font-size: 0.875rem;
  padding-right: 1.875rem;
  position: relative;
}
.infoBulle a i {
  position: absolute;
  right: 0;
  font-size: 1rem;
}
.infoBulle a:hover {
  text-decoration: underline !important;
}
.infoBulle .content {
  max-height: 8.125rem;
  overflow-y: auto;
}
.infoBulle .content::-webkit-scrollbar {
  width: 10px;
}
.infoBulle .content::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.infoBulle .content::-webkit-scrollbar-thumb,
.infoBulle .content::-webkit-scrollbar-thumb:hover {
  background: #3363cc;
}
.wrapperInfoBulle {
  position: relative;
  width: 1.875rem;
}
.wrapperInfoBulle:hover {
  width: auto;
}
.wrapperInfoBulle:hover .infoBulle {
  display: block;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 38%;
  left: 2.1875rem;
}
.inputField {
  max-width: 16.875rem;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}
.inputField input {
  width: 100%;
  height: 2.25rem;
  border: 0.0625rem solid #dadbe0;
  -moz-border-radius: 0.5rem;
  border-radius: 0.5rem;
  font-family: $Barlow;
  font-weight: 400;
  font-size: 0.875rem;
  color: #00008c;
  padding: 0 0.625rem;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.inputField input:focus {
  border-color: #00008c;
}
.inputField label {
  font-family: $BarlowMedium;
  font-weight: 500;
  font-size: 0.875rem;
  color: #00008c;
}
#header {
  position: relative;
  background: #fff;
  -webkit-box-shadow: 0 1px 10px rgba(0, 0, 46, 0.06);
  -moz-box-shadow: 0 1px 10px rgba(0, 0, 46, 0.06);
  box-shadow: 0 1px 10px rgba(0, 0, 46, 0.06);
  height: 60px;
}
.menuBurger {
  max-width: 1.25rem;
  width: 100%;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  height: 30px;
}
.menuBurger span {
  position: absolute;
  width: 100%;
  height: 0.125rem;
  background: #00f;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.menuBurger span:first-child {
  top: 0;
}
.menuBurger span:nth-child(2),
.menuBurger span:nth-child(3) {
  top: 6px;
}
.menuBurger span:last-child {
  top: 12px;
}
.menuBurger.open span:first-child,
.menuBurger.open span:last-child {
  display: none;
}
.menuBurger.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.menuBurger.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.menuMobile {
  position: absolute;
  right: -150%;
  max-width: 15rem;
  width: 100%;
  background: #fff;
  max-height: 120vh;
  height: 100vh;
  top: 60px;
  padding: 1.5rem;
  z-index: 2;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-box-shadow: 0 1px 10px rgba(0, 0, 46, 0.06) inset;
  -moz-box-shadow: 0 1px 10px rgba(0, 0, 46, 0.06) inset;
  box-shadow: inset 0 1px 10px rgba(0, 0, 46, 0.06);
}
.menuMobile.open {
  right: 0;
}
.menuMobile .mainMenu {
  margin-bottom: 3.75rem;
  display: block;
}
.menuMobile .mainMenu li {
  display: block;
  color: #00008c;
  font-family: $MontserratSemiBold;
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 0.625rem 0 0.625rem 3.125rem;
  -moz-border-radius: 1.5625rem;
  border-radius: 1.5625rem;
  position: relative;
  margin-bottom: 0.125rem;
}
.menuMobile .mainMenu li.current,
.menuMobile .mainMenu li:hover {
  background: #f2f2ff;
}
.menuMobile .profile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5rem;
  position: relative;
}
.menuMobile .profile:after {
  content: "";
  position: absolute;
  bottom: -2.5rem;
  width: 10rem;
  height: 1px;
  background: #ececef;
}
.menuMobile .profile_avatar {
  display: inline-block;
  color: #fff;
  width: 3rem;
  height: 3rem;
  text-align: center;
  line-height: 3rem;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #3363cc;
  font-family: $MontserratSemiBold;
  font-weight: 600;
  font-size: 1.125rem;
  margin-bottom: 0.9375rem;
}
.menuMobile .profile_fullName {
  display: block;
  margin: 0 auto;
  font-family: $MontserratSemiBold;
  font-weight: 600;
  font-size: 0.875rem;
  color: #00008c;
  max-width: 8.75rem;
  width: 100%;
  line-height: 1.25rem;
  margin-bottom: 0.625rem;
}
.menuMobile .profile_link {
  font-family: $Barlow;
  font-weight: 400;
  font-size: 0.875rem;
  color: #00f;
  margin-bottom: 1.875rem;
}
.menuMobile .profile .logout {
  border: none;
  background: none;
  color: #d00000;
  font-family: $BarlowSemiBold;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.3px;
  cursor: pointer;
  position: relative;
}
.menuMobile .profile .logout:before {
  content: "";
  position: absolute;
  left: -1.875rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1.25rem;
  height: 1.25rem;
  background: url(/assets/img/icons/logout.jpg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.menuMobile .main-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.menuMobile .main-footer li a {
  display: inline-block;
  color: #00008c;
  font-family: $Barlow;
  font-weight: 400;
  font-size: 0.875rem;
  margin-bottom: 0.9375rem;
}
.menuMobile .main-footer li.version {
  font-family: $Barlow;
  font-weight: 400;
  font-size: 0.875rem;
  color: #666d92;
  margin-bottom: 1.875rem;
}
.menuMobile .main-footer li.trustDoca {
  width: 130px;
  margin-bottom: 3.75rem;
}
.btn-blue {
  height: 2.5rem;
  line-height: 1.875rem;
  padding: 0 1.0625rem;
  font-family: $BarlowMedium;
  font-weight: 500;
}
.btn-blue,
.btn-save {
  background: #00f;
  text-align: center;
  color: #fff;
  border: 0.0625rem solid #00f;
  cursor: pointer;
  font-size: 0.875rem;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.btn-save {
  height: 2rem;
  line-height: 1.9375rem;
  padding: 0 1.875rem;
  font-family: $MontserratSemiBold;
  font-weight: 600;
  background: #00f;
  -webkit-box-shadow: 0 0 0 #fff inset;
  -moz-box-shadow: 0 0 0 #fff inset;
  box-shadow: inset 0 0 0 #fff;
}
.btn-save:hover {
  -webkit-box-shadow: 0 -2rem 0 #fff inset;
  -moz-box-shadow: 0 -2rem 0 #fff inset;
  box-shadow: inset 0 -2rem 0 #fff;
  color: #00f;
}
.btn-save:disabled {
  background: #ececef;
  border-color: #ececef;
  color: #c6c8d0;
  cursor: default;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.radius10 {
  -moz-border-radius: 0.5rem;
  border-radius: 0.5rem;
}
.radius50 {
  -moz-border-radius: 3.125rem;
  border-radius: 3.125rem;
}
.btn-reset {
  background: transparent;
  border: none;
  color: #00f;
  font-family: $MontserratSemiBold;
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
  margin-right: 0.625rem;
  position: relative;
}
.btn-reset:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 0.0625rem;
  background: #00f;
  bottom: 0;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.btn-reset:hover:after {
  width: 0;
}
.popSel {
  position: fixed;
  top: -100%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 56.25rem;
  width: 100%;
  -webkit-box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.12);
  -moz-border-radius: 10px;
  border-radius: 10px;
  z-index: 9999;
  background: #fff;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.popSel.openModal {
  top: 50%;
}
.popSel_head {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -moz-border-radius-topleft: 10px;
  border-top-left-radius: 10px;
  -moz-border-radius-topright: 10px;
  border-top-right-radius: 10px;
  padding: 1.875rem 1.9375rem;
  background: #f2f2ff;
  position: relative;
  color: #00008c;
}
.popSel_head--title {
  font-family: $MontserratSemiBold;
  font-weight: 600;
  font-size: 1rem;
  position: relative;
  margin-bottom: 0px !important;
}
.popSel_head--title:after {
  content: "";
  position: absolute;
  left: -30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  -webkit-background-size: 20px 20px;
  -moz-background-size: 20px;
  background-size: 20px;
}
.popSel_head--close {
  position: absolute;
  right: -3.75rem;
  top: 0;
  width: 2.5rem;
  height: 2.5rem;
  -moz-border-radius: 0.5rem;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 46, 0.04);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 46, 0.04);
  box-shadow: 0 0 10px rgba(0, 0, 46, 0.04);
  background: #fff;
  text-align: center;
  line-height: 2.5rem;
  color: #00008c;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.popSel_head--close:hover {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 46, 0.04) inset;
  -moz-box-shadow: 0 0 10px rgba(0, 0, 46, 0.04) inset;
  box-shadow: inset 0 0 10px rgba(0, 0, 46, 0.04);
}
.popSel_head--error {
  background: #fef4f5;
  color: #d00000;
}
.popSel_head--error .popSel_head--title {
  color: #d00000 !important;
  background: url("/assets/icons/attention.png") no-repeat center left;
  background-size: 23px;
}

.popSel_content {
  padding: 0 1.25rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
}
.popSel_content--wrapper {
  min-height: 3.125rem;
  max-height: 25rem;
  overflow-x: hidden;
  overflow-y: auto;
}
.popSel_footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1.875rem;
  background: #fff;
  -moz-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}
.popSel_footer--right {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.popSel_footer--center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.popSel_footer--border {
  border-top: 0.0625rem solid #dadbe0;
}
.popSel_tabs {
  max-width: 15.625rem;
  width: 100%;
  border-right: 0.0625rem solid #dadbe0;
  padding-top: 1.875rem;
  padding-right: 1.25rem;
  min-height: 25rem;
}
.popSel_tabs li {
  display: block;
  color: #00008c;
  font-family: $BarlowMedium;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 0.625rem 0 0.625rem 2.5rem;
  -moz-border-radius: 1.5625rem;
  border-radius: 1.5625rem;
  position: relative;
  margin-bottom: 0.125rem;
}
.popSel_tabs li.current,
.popSel_tabs li:hover {
  background: #f2f2ff;
}
.popSel .detailTabs {
  display: none;
  padding: 1.875rem 0 1.875rem 1.25rem;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.popSel .detailTabs.current {
  display: block;
}
.popSel_tabs li:before {
  left: 9px !important;
}
@media screen and (max-width: 769px) {
  .popSel {
    width: 95%;
    /* height: 100vh; */
  }
  .popSel_content {
    padding-left: 5px;
  }
  .popSel_tabs {
    max-width: 45px;
  }
  .popSel_tabs li {
    font-size: 0;
    padding: 20px;
  }
  .popSel_tabs li:before {
    left: 9px !important;
  }
  .popSel_head--close {
    right: 10px;
    top: 20px;
  }
}
.overleyPopup {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #cccedc;
  opacity: 0.4;
  z-index: 0;
  /*display: none;*/
}
.overleyPopup.open {
  display: block;
}
.btnDefault {
  border: none;
  background: #00f;
  color: #fff;
  height: 3rem;
  line-height: 3rem;
  -moz-border-radius: 3.125rem;
  border-radius: 3.125rem;
  padding: 0 1.875rem;
  cursor: pointer;
  font-family: $MontserratSemiBold;
  font-weight: 600;
  font-size: 0.875rem;
}
.iconSel {
  position: relative;
}
.iconSel:before {
  content: "";
  position: absolute;
  /* left: 0.875rem; */
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1.25rem;
  height: 1.25rem;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_info:before {
  background: url(/assets/img/icons/profil.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_condition:before {
  background: url(/assets/img/icons/preferences.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_file:before {
  background: url(/assets/img/icons/file.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_sms:before {
  background: url(/assets/img/icons/sms.svg) no-repeat scroll 50%;
  -webkit-background-size: 20px 20px;
  -moz-background-size: 20px;
  background-size: 20px;
}
.iconSel_settings:before {
  background: url(/assets/img/icons/settings.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_home:before {
  background: url(/assets/img/icons/home.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_sign:before {
  background: url(/assets/img/icons/sign-2.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_model:before {
  background: url(/assets/img/icons/model.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_contacts:before {
  background: url(/assets/img/icons/contact.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_statistique:before {
  background: url(/assets/img/icons/statistique.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_add:before {
  background: url(/assets/img/icons/add.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_analytics:before {
  background: url(/assets/img/icons/analytics.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_archive:before {
  background: url(/assets/img/icons/archive.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_arrowRight:before {
  background: url(/assets/img/icons/arrowRight.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_chatbox:before {
  background: url(/assets/img/icons/chatbox.svg) no-repeat scroll 50%;
  -webkit-background-size: 30px 30px;
  -moz-background-size: 30px;
  background-size: 30px;
}
.iconSel_checkbox:before {
  background: url(/assets/img/icons/checkbox.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_cle:before {
  background: url(/assets/img/icons/CLE.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_close:before {
  background: url(/assets/img/icons/close.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_closeCircle:before {
  background: url(/assets/img/icons/closeCircle.svg) no-repeat scroll 50%;
  -webkit-background-size: 18px 18px;
  -moz-background-size: 18px;
  background-size: 18px;
}
.iconSel_dossier:before {
  background: url(/assets/img/icons/dossier.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_contact:before {
  background: url(/assets/img/icons/contact.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_down:before {
  background: url(/assets/img/icons/down.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_download:before {
  background: url(/assets/img/icons/download.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_eccourier:before {
  background: url(/assets/img/icons/eCourrier.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_eccourierCopy:before {
  background: url(/assets/img/icons/eCourrierCopy.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_edit:before {
  background: url(/assets/img/icons/edit.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_email:before {
  background: url(/assets/img/icons/Email.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_export:before {
  background: url(/assets/img/icons/export.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_faq:before {
  background: url(/assets/img/icons/faq.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_filter:before {
  background: url(/assets/img/icons/filter.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_forbidden:before {
  background: url(/assets/img/icons/forbidden.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_hyperlink:before {
  background: url(/assets/img/icons/hyperlink.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_left:before {
  background: url(/assets/img/icons/left.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_link:before {
  background: url(/assets/img/icons/link.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_loader:before {
  background: url(/assets/img/icons/loader.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_lock:before {
  background: url(/assets/img/icons/lock.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_lre:before {
  background: url(/assets/img/icons/LRE.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_menu:before {
  background: url(/assets/img/icons/menu.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_moreX:before {
  background: url(/assets/img/icons/moreX.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_moreY:before {
  background: url(/assets/img/icons/moreY.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_preferences:before {
  background: url(/assets/img/icons/preferences.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_notification:before {
  background: url(/assets/img/icons/notification.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_ratio:before {
  background: url(/assets/img/icons/ratio.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_remove:before {
  background: url(/assets/img/icons/remove.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_right:before {
  background: url(/assets/img/icons/right.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_save:before {
  background: url(/assets/img/icons/save.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_search:before {
  background: url(/assets/img/icons/search.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_server:before {
  background: url(/assets/img/icons/server.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_shape:before {
  background: url(/assets/img/icons/shape.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_share:before {
  background: url(/assets/img/icons/share.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_trash:before {
  background: url(/assets/img/icons/trash.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_up:before {
  background: url(/assets/img/icons/up.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_upload:before {
  background: url(/assets/img/icons/upload.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_userGroup:before {
  background: url(/assets/img/icons/userGroup.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_userManagement:before {
  background: url(/assets/img/icons/userManagement.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_voir:before {
  background: url(/assets/img/icons/voir.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.iconSel_warning:before {
  background: url(/assets/img/icons/warning.svg) no-repeat scroll 50%;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px;
  background-size: 24px;
}
.flexSel {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}
.flexSel .itemSel {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.flexSel .itemSel:first-child {
  margin-right: 0.625rem;
}
.flexSel .itemSel:last-child {
  margin-left: 0.625rem;
}
.list-icon-sel {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 56.25rem;
}
.list-icon-sel li {
  width: 30px;
  height: 30px;
}

.my-scrollbar {
  --scrollbar-border-radius: 7px;
  --scrollbar-padding: 4px;
  --scrollbar-viewport-margin: 0;
  --scrollbar-track-color: #e5e5fa !important;
  --scrollbar-wrapper-color: transparent;
  --scrollbar-thumb-color: #2929df !important;
  --scrollbar-thumb-hover-color: #1515a8 !important;
  --scrollbar-size: 9px !important;
  --scrollbar-hover-size: var(--scrollbar-size);
  --scrollbar-thumb-transition: height ease-out 150ms, width ease-out 150ms;
  --scrollbar-track-transition: height ease-out 150ms, width ease-out 150ms;
  width: 100%;
}
.scroll-to-right {
  position: relative;
  right: -20px;
}
.ng-scroll-viewport-wrapper {
  padding-right: 20px !important;
}
.popSel_content_items .detailTabs {
  max-height: 100%;
}
@media screen and (max-width: 48em) {
  .popSel_content_items .popSel_head--close {
    right: 0;
    top: -0.9375rem;
    width: 1.875rem;
    height: 1.875rem;
    line-height: 1.875rem;
  }
}
.popSel_content_items.open {
  top: 50%;
}
.popSelItms {
  width: -webkit-calc(100%/3 - 20px);
  width: -moz-calc(100%/3 - 20px);
  width: calc(100% / 3 - 20px);
  -moz-border-radius: 0.625rem;
  border-radius: 0.625rem;
  border: 0.0625rem solid #ececef;
  padding: 1.25rem;
  text-align: center;
  margin-right: 1.25rem;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
@media screen and (max-width: 48em) {
  .popSelItms {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1.25rem;
  }
}
.popSelItms:hover {
  -webkit-box-shadow: 0 0 0.625rem #666d92;
  -moz-box-shadow: 0 0 0.625rem #666d92;
  box-shadow: 0 0 0.625rem #666d92;
}
.popSelItms--icon {
  margin-bottom: 1.25rem;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  background: #f2f2ff;
  -moz-border-radius: 3.125rem;
  border-radius: 3.125rem;
  text-align: center;
}
.popSelItms .iconSel:before {
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.popSelItms--title {
  font-family: $MontserratMedium;
  font-weight: 500;
  color: #00008c;
  font-size: 1.25rem;
  position: relative;
  margin-bottom: 1.25rem;
}
.popSelItms--desc {
  font-family: $Barlow;
  font-weight: 300;
  color: #9fa4bb;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  position: relative;
  margin: 0 auto;
  margin-bottom: 1.25rem;
}
.overleyBody {
  overflow: hidden;
}
.overleyBody .footer {
  display: none !important;
  z-index: 0;
}
.overleyBody .my-scrollbar {
  overflow: hidden !important;
  max-height: 100vh !important;
}
.overleyBody .business-body {
  min-height: 800px !important;
  max-height: 800px !important;
  overflow: hidden !important;
}
.popSel.openModal:after {
  content: "";
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  width: 500vw;
  height: 500vh;
  top: -100vh;
  left: -100vw;
  z-index: -11;
}

#header img {
  width: 60%;
}

.ng-arrow-wrapper span {
  display: none !important;
}

.ng-arrow-wrapper:before {
  content: url(/assets/icons/down.svg);
  position: absolute;
  top: -10px;
  right: 0.625rem;
  width: 1.5rem;
  height: 1.25rem;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  cursor: pointer;
  scale: 0.7;
}

.ng-select.ng-select-opened .ng-arrow-wrapper:before {
  top: -10px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.ng-dropdown-header input {
  border: 1px solid #ccc;
}
.btn-digiposte {
  background-color: #5c5bff;
  border: #5c5bff;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding: 10px 22px 10px 22px !important;
  align-items: center;
  letter-spacing: 0.9px;
}
.btn-download {
  color: #1d23f5;
  font-weight: bold;
  letter-spacing: 0.5px !important;
}
.btn-download:hover {
  color: #6e76e6;
  background-color: #eaebf8;
}
.btn-download:focus {
  background-color: #d6d8f2;
  color: #4850bc;
}
.btn-digiposte:hover {
  background-color: #4545db;
  color: #fff;
}
.digiposteIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.cancelPopup {
  background: #fee6eb !important;
}
.cancelPopup .modal-title {
  color: #d92c01;
}
datatable-body-cell .datatable-checkbox {
  margin-top: 8px;
}
.datatable-header-cell-label {
  font-family: $BarlowSemiBold;
  font-weight: 600;
  letter-spacing: normal;
  font-size: 14px !important;
}
.selected-column {
  margin-bottom: 0 !important;
  border-bottom: 1px solid #b3c1ff;
}
.selected-column > p {
  font-family: $BarlowMedium;
  color: #00008c;
  font-size: 14px;
}
.selected-column .wrapper-btn-action .btn.btn-outline-primary {
  font-family: $BarlowSemiBold !important;
  font-weight: 600 !important;
  letter-spacing: 0.4px !important;
  font-size: 14px !important;
}
.selected-column .wrapper-btn-action .btn.btn-outline-primary:hover {
  background: #0000ff !important;
  border-color: #0000ff !important;
  color: #ffffff !important;
}
.selected-column .wrapper-btn-action .btn i {
  font-size: 20px;
  position: relative;
  top: 2px;
}
.selected-column .wrapper-btn-action .separator {
  display: inline-block;
  width: 1px;
  height: 30px;
  background: #00008c;
  position: relative;
  top: 13px;
}
.selected-column .wrapper-btn-action .separator + .btn-ghost-primary i {
  color: #00008c;
  font-size: 26px !important;
  transform: scale(1);
  transition: all 0.3s ease;
  border: blue !important;
}
.selected-column .wrapper-btn-action .separator + .btn-ghost-primary:hover {
  background: transparent !important;
  transform: scale(1.1);
}
.nav-tabs .nav-link {
  font-family: $MontserratSemiBold !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  letter-spacing: 0.4px;
}
.checkboxe-cell {
  margin-right: 10px;
}
.datatable-row-center .datatable-header-cell {
  margin-right: 20px;
}
.nav-tabs .nav-link .icon-profile {
  padding-right: 5px;
  padding-left: 30px;
  background: url(/assets/icons/profil.png) no-repeat left center;
}
.btn-upload {
  padding-right: 40px !important;
  padding-left: 40px !important;
  background: url(/assets/icons/upload.png) no-repeat right center;
  background-position: 95%;
}
.btn-mail {
  padding-right: 10px !important;
  padding-left: 40px !important;
  background: url(/assets/icons/mail.png) no-repeat;
  background-position: 95%;
  background-size: 40%;
  margin-left: -24px;
}
.wrapperSteps {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.wrapperSteps .step-border {
  border: none !important;
}
.new-step-item {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  font-family: $MontserratSemiBold;
  font-weight: 600;
  background-color: #0000ff;
  line-height: 30px;
  letter-spacing: 0.4px;
}

.wrapperSteps .new-step-item {
  background: #0000ff;
}
.wrapperSteps .wrapperSteps_title {
  font-family: $MontserratSemiBold;
  color: #0000ff;
  font-size: 16px;
  margin-left: 15px;
  letter-spacing: 0.4px;
}
.ng-clear-wrapper {
  display: none !important;
}
.card-body-pd {
  padding-top: 30px !important;
  padding-bottom: 10px !important;
  padding-left: 25px !important;
  padding-right: 22px !important;
}
.wrapperSteps + .card {
  margin-left: 45px !important;
}
.wrapperActionSteps .card {
  margin-left: 40px !important;
  position: relative;
}
.wrapperActionSteps .card:after {
  content: "";
  width: 1px;
  height: 100%;
  background: #00008c;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -28px;
}
.btn-next-stepper {
  position: relative;
  font-family: $MontserratSemiBold !important;
  letter-spacing: 0.4px !important;
}
.btn-next-stepper:after {
  content: "\f2fb";
  font-family: Material-Design-Iconic-Font;
  font-size: 28px;
  color: #ffffff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}
.width-button {
  width: 100%;
  box-sizing: border-box;
}
.btn-next-stepper:disabled:after {
  color: #c6c8d0;
}
.bloc-mini-card-margin .font-label {
  color: #00008c !important;
  font-family: $BarlowMedium !important;
  font-size: 14px !important;
}
.customerdropdown .dropdownSel3_wrapper {
  width: 250px !important;
}
@media screen and (max-width: 450px) {
  .file-upload .dropzone {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .file-upload .dropzone .btn-upload .btn-mail {
    padding-left: 10px !important;
    padding-right: 10px !important;
    width: 100%;
    background: none;
  }
  .file-upload-list .files .dropdown-toggle {
    position: absolute !important;
    top: -30px !important;
    right: -10px;
    transform: rotate(90deg) translateY(0) !important;
  }
  section .wrapperSteps {
    padding-left: 0 !important;
    padding-right: 0 !important;
    position: relative;
    left: -10px;
  }
  section .wrapperSteps app-stepper {
    position: relative !important;
    left: -20px !important;
  }
  section .wrapperSteps app-stepper + div.d-flex {
    position: relative !important;
    right: 35px !important;
  }
  .wrapperSteps + .card {
    margin-left: 0 !important;
  }
  .btn-mobile {
    font-size: 12px !important;
    width: 100%;
  }
  app-mini-card .rounded {
    margin-right: 20px;
  }
}

.confirmationText {
  font-family: $Montserrat;
  font-size: 22px;
}
.textDescription {
  font-family: $Barlow;
}
.btn.btn-primary:focus {
  outline: 2px solid #0056b3 !important;
  outline-offset: 2px !important;
  box-shadow: 0 0 5px rgba(0, 86, 179, 0.5) !important;
}
.btn.btn-ghost-ghost:focus {
  outline: 2px solid #0056b3 !important;
  outline-offset: 2px !important;
}

.iconSel_external_link:before {
  background: url(/assets/icons/external-link.svg) no-repeat scroll 50%;
  -webkit-background-size: 17px 17px;
  -moz-background-size: 17px;
  background-size: 17px;
  top: -5px;
}
.sel-body {
  margin: 0;
  font-family: $Barlow;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #565b72;
  text-align: left;
  background-color: #f8f8fb;
}
.sel-body h1,
.sel-body h2,
.sel-body h3,
.sel-body .h1,
.sel-body .h2,
.sel-body .h3 {
  font-family: $MontserratMedium;
  font-weight: 500 !important;
}
.sel-body h1,
.sel-body .h1 {
  font-size: 2.5rem;
}
.sel-body h3,
.sel-body .h3 {
  font-size: 1.75rem;
}
@media only screen and (max-width: 768px) {
  .wrapperMentions {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .wrapperMentions h1 {
    font-size: 22px;
  }
  .wrapperMentions p {
    font-size: 16px;
    line-height: 26px;
  }
  .sel-body h3,
  .sel-body .h3 {
    font-size: 1.2rem;
  }
}
.cke.cke_reset_all {
  position: relative !important;
  top: 250px !important;
}
.cke_wysiwyg_frame {
  min-height: 30rem !important;
}
.cke_contents {
  min-height: 30rem !important;
}
@media screen and (max-width: 769px) {
  .cke.cke_reset_all {
    position: relative !important;
    top: 545px !important;
  }
}

.app-notification {
  position: fixed !important;
  top: 3.6rem;
  right: 0.8rem;
  z-index: 1051 !important;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}

.hr-border {
  height: 1px;
  background-color: #dfdfdf;
}

.img-thumbnail.presentation-color {
  width: 96px;
  height: 64px;
  flex: 0 0 96px;
}

pre[class*="language-"] {
  padding: 20px;

  code,
  span {
    font-size: 13px;
  }
}

.bg-lynch {
  background: $lynch;
}
.disableScroll {
  overflow: hidden;
}
// for cdk overlay
.translated {
  white-space: pre-line;
  margin-bottom: 20px;
}

.menu-container {
  min-width: 112px;
  max-width: 280px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 117px); // 117px footer hieght + navbar hieght + 1px
  border-radius: 4px;
  outline: 0;
  min-height: 64px;
  background: #fff;
  // elvation-4:
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  z-index: 11000;
  margin-top: 1.5rem;
  padding: 1rem;
}

.menu-panel {
  display: flex;
  min-height: 0px;
  border-radius: 1rem;
}

.menu-backdrop-mobile {
  background-color: #00000030;
}

.cursor-pointer {
  cursor: pointer;
}

.link-click {
  margin: 0 0 2px;
  font-family: $Barlow;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: $primary;
  border-bottom: 2px solid;
  cursor: pointer;
}
.ng2-pdf-viewer-container {
  overflow: auto;
  position: relative;
  height: 85%;
}

.popover-body {
  padding: 0.875rem 0.875rem;
  color: #ffffff !important;
  background-color: #3970e6;
  border-radius: 0.5rem;
}

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #3970e6 !important;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #2929df #e5e5fa;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #e5e5fa;
}

*::-webkit-scrollbar-thumb {
  background-color: #2929df;
  border-radius: 20px;
  border: 3px solid #e5e5fa;
}

.ngx-otp-input-box.my-super-box-class {
  input {
    width: 47px;
    height: 55px;
    text-align: center;
    font-size: 2.5rem;
    border: 1px solid #e0e1e3;
    border-radius: 10px;
    outline: 0;
    color: #00008c;
  }
}

/** ckeditor style **/
.removeformat_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -1728px !important;
}

.newpage_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -1488px !important;
}

.cut_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -312px !important;
}
.copy_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -264px !important;
}
.paste_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -360px !important;
}
.undo_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -2040px !important;
}
.redo_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -1992px !important;
}
.bold_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -24px !important;
}
.italic_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -48px !important;
}
.underline_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -144px !important;
}
.strike_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -72px !important;
}
.numberedlist_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -1416px !important;
}
.bulletedlist_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -1368px !important;
}
.outdent_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -1080px !important;
}
.justifyleft_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -1176px !important;
}
.justifycenter_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -1152px !important;
}
.justifyright_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -1200px !important;
}
.justifyblock_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -1128px !important;
}
.link_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -1296px !important;
}
.image_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -984px !important;
}
.specialchar_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -1896px !important;
}
.textcolor_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -408px !important;
}
.bgcolor_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -384px !important;
}
.indent_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -1032px !important;
}
.bidiltr_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -168px !important;
}
.bidirtl_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -192px !important;
}
.maximize_icon {
  background: url(/assets/icons/ckeditor_icons.png) no-repeat 0 -1440px !important;
}

.cke_combo_text {
  color: #0000ff !important;
}

.cke_combo_arrow {
  border-top: 3px solid #0000ff !important;
}

.cke_button_arrow {
  border-top: 3px solid #0000ff !important;
}

.input-rightPadding {
  padding-right: 100px !important;
}

.max-content {
  width: max-content;
}

.modal-size-modal-template {
  width: calc(100% - 240px) !important;
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}
.modal-size-modal-template > .modal-content > .modal-content-wrapper {
  max-height: calc(100% - 240px);
}
.modal-size-modal-template > .modal-content > .modal-body {
  padding-top: 0px;
  padding-bottom: 0px;
}
.modal-size-modal-template > .modal-content {
  height: calc(100% - 240px) !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.thenText {
  font-family: $Montserrat;
  font-size: 16px;
  margin-bottom: 0;
}
.modal-size-modal-recipient > .modal-content {
  width: auto !important;
}

.hw100 {
  width: 100% !important;
  height: 100% !important;
}

.custom-modal-dialog {
  display: flex;
  justify-content: center;
}
.modal-custom-modal-dialog > .modal-content {
  width: 1200px !important;
}

ngb-modal-backdrop {
  z-index: 1040 !important;
}
