// weight and italics override Bootstrap 4
/* font weight */
.font-weight-bold {
  font-family: $font-family-weight-bold;
}
.font-weight-normal {
  font-family: $font-family-weight-normal;
}
.font-weight-light {
  font-family: $font-family-weight-light;
}
/* font style */
.font-italic {
  font-family: $font-family-weight-normal-italic;
}
.font-weight-bold-italic {
  font-family: $font-family-weight-bold-italic;
}
.font-weight-light-italic {
  font-family: $font-family-weight-light-italic;
}
.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
