.toast {
  max-width: $toast-max-width;
  border: none;
  .toast-body {
    display: none;
  }
  .toast-header {
    padding: 0;
    border: 0;
    align-items: initial;
    border-radius: $toast-header-border-radius;
    &:before {
      width: 8px;
      content: "";
      display: block;
      border-radius: $toast-header-border-radius 0 0 $toast-header-border-radius;
    }
    .container-toast-description {
      padding: $toast-inside-spacer-y $toast-inside-spacer-x;
      align-items: center;
      display: flex;
      flex-grow: 1;
      gap: 8px;
    }
    .btn-close {
      display: none;
    }
    .toast-text {
      word-break: break-word;
      flex-grow: 1;
      font-family: $Montserrat;
      font-size: 0.875rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.15;
      letter-spacing: -0.14px;
      text-align: left;
      color: var(--secondary-ultramarine-2, #00008c);
      .text-header {
        margin-bottom: 0.35rem;
      }
    }
    button.close {
      font-size: 31px;
      color: #00008c;
      opacity: 1;
      margin-top: 15px;
      margin-right: 20px;
      align-self: self-start;
    }
  }
  &.toast-success {
    i[class^="icon"]:before {
      color: $success;
      content: "\e80f";
    }
  }
  &.toast-warning {
    .toast-header:before {
      background: $warning;
    }
    i[class^="icon"]:before {
      color: $warning;
      content: "\e811";
    }
  }
  &.toast-danger {
    .toast-header:before {
      background: $danger;
    }
    i[class^="icon"]:before {
      color: $danger;
      content: "\e938";
    }
  }
}
