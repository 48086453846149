.dropdown-container {
  .dropdown-menu {
    padding: 0;
    .dropdown-item {
      border-bottom: 1px solid $gray-300;
      padding: 0.75rem 1.5rem;
      &:last-child {
        border-bottom: none;
        border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;
      }
      &:first-child {
        border-radius: $dropdown-border-radius $dropdown-border-radius 0 0;
      }
    }
  }
  .dropdown-toggle {
    display: flex;
    align-items: center;
    span {
      flex-direction: column;
      flex-grow: 1;
      display: block;
      @include text-truncate();
    }
    &:after {
      content: "\e831";
      font-family: $iconDocaposte;
      border: none;
    }
  }
  .dropup {
    .dropdown-toggle:after {
      content: "\e831";
      font-family: $iconDocaposte;
      border: none;
    }
  }
  .show {
    .dropdown-toggle:after {
      content: "\e82e";
      font-family: $iconDocaposte;
      border: none;
    }
  }
}
