[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: $iconDocaposte;
  font-style: normal;
  font-weight: normal;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

i {
  display: inline-block;
}
.icon-location:before {
  content: "\e800";
}
.icon-bullet-point:before {
  content: "\e801";
}
.icon-x-square:before {
  content: "\e802";
}
.icon-x-octagon:before {
  content: "\e803";
}
.icon-x-circle:before {
  content: "\e804";
}
.icon-slash:before {
  content: "\e805";
}
.icon-plus-square:before {
  content: "\e806";
}
.icon-plus-circle:before {
  content: "\e807";
}
.icon-minus-square:before {
  content: "\e808";
}
.icon-minus-circle:before {
  content: "\e809";
}
.icon-info-fill:before {
  content: "\e80a";
}
.icon-info:before {
  content: "\e80b";
}
.icon-help-circle:before {
  content: "\e80c";
}
.icon-check:before {
  content: "\e80d";
}
.icon-check-square:before {
  content: "\e80e";
}
.icon-check-circle:before {
  content: "\e80f";
}
.icon-alert-octagon:before {
  content: "\e810";
}
.icon-alert-circle:before {
  content: "\e811";
}
.icon-refresh-cw:before {
  content: "\e812";
}
.icon-refresh-ccw:before {
  content: "\e813";
}
.icon-arrow-up:before {
  content: "\e814";
}
.icon-arrow-up-right:before {
  content: "\e815";
}
.icon-arrow-up-left:before {
  content: "\e816";
}
.icon-arrow-up-circle:before {
  content: "\e817";
}
.icon-arrow-right:before {
  content: "\e818";
}
.icon-arrow-right-circle:before {
  content: "\e819";
}
.icon-arrow-left:before {
  content: "\e81a";
}
.icon-arrow-left-circle:before {
  content: "\e81b";
}
.icon-arrow-down:before {
  content: "\e81c";
}
.icon-arrow-down-right:before {
  content: "\e81d";
}
.icon-arrow-down-left:before {
  content: "\e81e";
}
.icon-arrow-down-circle:before {
  content: "\e81f";
}
.icon-rotate-cw:before {
  content: "\e820";
}
.icon-rotate-ccw:before {
  content: "\e821";
}
.icon-corner-up-right:before {
  content: "\e822";
}
.icon-corner-up-left:before {
  content: "\e823";
}
.icon-corner-right-up:before {
  content: "\e824";
}
.icon-corner-right-down:before {
  content: "\e825";
}
.icon-corner-left-up:before {
  content: "\e826";
}
.icon-corner-left-down:before {
  content: "\e827";
}
.icon-corner-down-right:before {
  content: "\e828";
}
.icon-corner-down-left:before {
  content: "\e829";
}
.icon-chevrons-up:before {
  content: "\e82a";
}
.icon-chevrons-right:before {
  content: "\e82b";
}
.icon-chevrons-left:before {
  content: "\e82c";
}
.icon-chevrons-down:before {
  content: "\e82d";
}
.icon-chevron-up:before {
  content: "\e82e";
}
.icon-chevron-right:before {
  content: "\e82f";
}
.icon-chevron-left:before {
  content: "\e830";
}
.icon-chevron-down:before {
  content: "\e831";
}
.icon-external-link:before {
  content: "\e832";
}
.icon-upload:before {
  content: "\e833";
}
.icon-upload-cloud:before {
  content: "\e834";
}
.icon-log-out:before {
  content: "\e835";
}
.icon-log-in:before {
  content: "\e836";
}
.icon-download:before {
  content: "\e837";
}
.icon-download-cloud:before {
  content: "\e838";
}
.icon-x:before {
  content: "\e839";
}
.icon-power:before {
  content: "\e83a";
}
.icon-plus:before {
  content: "\e83b";
}
.icon-navigation:before {
  content: "\e83c";
}
.icon-navigation-2:before {
  content: "\e83d";
}
.icon-mouse-pointer:before {
  content: "\e83e";
}
.icon-more-vertical:before {
  content: "\e83f";
}
.icon-more-horizontal:before {
  content: "\e840";
}
.icon-minus:before {
  content: "\e841";
}
.icon-menu:before {
  content: "\e842";
}
.icon-loader:before {
  content: "\e843";
}
.icon-menu-notif:before {
  content: "\e845";
}
.icon-reverse:before {
  content: "\e846";
}
.icon-radio:before {
  content: "\e847";
}
.icon-cast:before {
  content: "\e848";
}
.icon-pause-circle:before {
  content: "\e849";
}
.icon-airplay:before {
  content: "\e84a";
}
.icon-pause:before {
  content: "\e84b";
}
.icon-mic-off:before {
  content: "\e84c";
}
.icon-mic:before {
  content: "\e84d";
}
.icon-camera-off:before {
  content: "\e84e";
}
.icon-camera:before {
  content: "\e84f";
}
.icon-stop-circle:before {
  content: "\e850";
}
.icon-play:before {
  content: "\e851";
}
.icon-image:before {
  content: "\e852";
}
.icon-film:before {
  content: "\e853";
}
.icon-music:before {
  content: "\e854";
}
.icon-skip-forward:before {
  content: "\e855";
}
.icon-play-circle:before {
  content: "\e856";
}
.icon-skip-back:before {
  content: "\e857";
}
.icon-rewind:before {
  content: "\e858";
}
.icon-fast-forward:before {
  content: "\e859";
}
.icon-volume-2:before {
  content: "\e85a";
}
.icon-volume:before {
  content: "\e85b";
}
.icon-volume-x:before {
  content: "\e85c";
}
.icon-volume-1:before {
  content: "\e85d";
}
.icon-voicemail:before {
  content: "\e85e";
}
.icon-video:before {
  content: "\e85f";
}
.icon-video-off:before {
  content: "\e860";
}
.icon-printer:before {
  content: "\e861";
}
.icon-tablet:before {
  content: "\e862";
}
.icon-watch:before {
  content: "\e863";
}
.icon-tv:before {
  content: "\e864";
}
.icon-speaker:before {
  content: "\e865";
}
.icon-smartphone:before {
  content: "\e866";
}
.icon-headphones:before {
  content: "\e867";
}
.icon-monitor:before {
  content: "\e868";
}
.icon-rss:before {
  content: "\e869";
}
.icon-wifi:before {
  content: "\e86a";
}
.icon-wifi-off:before {
  content: "\e86b";
}
.icon-bluetooth:before {
  content: "\e86c";
}
.icon-battery-charging:before {
  content: "\e86d";
}
.icon-zap:before {
  content: "\e86e";
}
.icon-zap-off:before {
  content: "\e86f";
}
.icon-battery:before {
  content: "\e870";
}
.icon-disc:before {
  content: "\e871";
}
.icon-phone-call:before {
  content: "\e872";
}
.icon-phone:before {
  content: "\e873";
}
.icon-phone-outgoing:before {
  content: "\e874";
}
.icon-phone-off:before {
  content: "\e875";
}
.icon-phone-missed:before {
  content: "\e876";
}
.icon-phone-incoming:before {
  content: "\e877";
}
.icon-phone-forwarded:before {
  content: "\e878";
}
.icon-shuffle:before {
  content: "\e879";
}
.icon-repeat:before {
  content: "\e87a";
}
.icon-clock-history:before {
  content: "\e87b";
}
.icon-users:before {
  content: "\e87c";
}
.icon-user-notif:before {
  content: "\e87d";
}
.icon-user:before {
  content: "\e87e";
}
.icon-user-x:before {
  content: "\e87f";
}
.icon-user-plus:before {
  content: "\e880";
}
.icon-user-minus:before {
  content: "\e881";
}
.icon-user-check:before {
  content: "\e882";
}
.icon-smile:before {
  content: "\e883";
}
.icon-meh:before {
  content: "\e884";
}
.icon-frown:before {
  content: "\e885";
}
.icon-message-square:before {
  content: "\e886";
}
.icon-message-square-1:before {
  content: "\e887";
}
.icon-message-circle-notif:before {
  content: "\e888";
}
.icon-message-circle:before {
  content: "\e889";
}
.icon-relaunch:before {
  content: "\e88a";
}
.icon-mail-notif:before {
  content: "\e88b";
}
.icon-mail:before {
  content: "\e88c";
}
.icon-send:before {
  content: "\e88d";
}
.icon-paperclip:before {
  content: "\e88e";
}
.icon-at-sign:before {
  content: "\e88f";
}
.icon-trash:before {
  content: "\e890";
}
.icon-trash-2:before {
  content: "\e891";
}
.icon-bell-notif:before {
  content: "\e892";
}
.icon-travel:before {
  content: "\e893";
}
.icon-eye-off:before {
  content: "\e894";
}
.icon-unlock:before {
  content: "\e895";
}
.icon-toggle-right:before {
  content: "\e896";
}
.icon-toggle-left:before {
  content: "\e897";
}
.icon-thumbs-up:before {
  content: "\e898";
}
.icon-thumbs-down:before {
  content: "\e899";
}
.icon-tag:before {
  content: "\e89a";
}
.icon-star-fill:before {
  content: "\e89b";
}
.icon-star:before {
  content: "\e89c";
}
.icon-shield:before {
  content: "\e89d";
}
.icon-shield-off:before {
  content: "\e89e";
}
.icon-share-2:before {
  content: "\e89f";
}
.icon-settings:before {
  content: "\e8a0";
}
.icon-save:before {
  content: "\e8a2";
}
.icon-map:before {
  content: "\e8a3";
}
.icon-map-pin:before {
  content: "\e8a4";
}
.icon-lock:before {
  content: "\e8a5";
}
.icon-link:before {
  content: "\e8a6";
}
.icon-heart-notif:before {
  content: "\e8a7";
}
.icon-heart:before {
  content: "\e8a8";
}
.icon-filter:before {
  content: "\e8aa";
}
.icon-eye:before {
  content: "\e8ab";
}
.icon-clock-notif:before {
  content: "\e8ac";
}
.icon-clock:before {
  content: "\e8ad";
}
.icon-calendar:before {
  content: "\e8ae";
}
.icon-bookmark:before {
  content: "\e8af";
}
.icon-bell:before {
  content: "\e8b0";
}
.icon-bell-off:before {
  content: "\e8b1";
}
.icon-rocket:before {
  content: "\e8b2";
}
.icon-twitter:before {
  content: "\e8b4";
}
.icon-twitch:before {
  content: "\e8b5";
}
.icon-youtube:before {
  content: "\e8b6";
}
.icon-slack:before {
  content: "\e8b7";
}
.icon-figma:before {
  content: "\e8b8";
}
.icon-feather:before {
  content: "\e8b9";
}
.icon-facebook:before {
  content: "\e8ba";
}
.icon-dribbble:before {
  content: "\e8bb";
}
.icon-github:before {
  content: "\e8bc";
}
.icon-linkedin:before {
  content: "\e8bd";
}
.icon-gitlab:before {
  content: "\e8be";
}
.icon-trello:before {
  content: "\e8bf";
}
.icon-instagram:before {
  content: "\e8c0";
}
.icon-aperture:before {
  content: "\e8c1";
}
.icon-docaposte:before {
  content: "\e8c3";
}
.icon-codesandbox:before {
  content: "\e8c4";
}
.icon-chrome:before {
  content: "\e8c5";
}
.icon-triangle:before {
  content: "\e8c6";
}
.icon-trending-up:before {
  content: "\e8c7";
}
.icon-trending-down:before {
  content: "\e8c8";
}
.icon-terminal:before {
  content: "\e8c9";
}
.icon-square-fill:before {
  content: "\e8ca";
}
.icon-square:before {
  content: "\e8cb";
}
.icon-pie-chart:before {
  content: "\e8cc";
}
.icon-percent:before {
  content: "\e8cd";
}
.icon-octagon:before {
  content: "\e8ce";
}
.icon-hexagon:before {
  content: "\e8cf";
}
.icon-hash:before {
  content: "\e8d0";
}
.icon-droplet:before {
  content: "\e8d1";
}
.icon-euro-sign:before {
  content: "\e8d2";
}
.icon-dollar-sign:before {
  content: "\e8d3";
}
.icon-divide:before {
  content: "\e8d4";
}
.icon-delete:before {
  content: "\e8d5";
}
.icon-command:before {
  content: "\e8d6";
}
.icon-code:before {
  content: "\e8d7";
}
.icon-circle-fill:before {
  content: "\e8d8";
}
.icon-circle:before {
  content: "\e8d9";
}
.icon-bar-chart:before {
  content: "\e8da";
}
.icon-bar-chart-2:before {
  content: "\e8db";
}
.icon-umbrella:before {
  content: "\e8dc";
}
.icon-thermometer:before {
  content: "\e8dd";
}
.icon-wind:before {
  content: "\e8de";
}
.icon-moon:before {
  content: "\e8df";
}
.icon-cloud-off:before {
  content: "\e8e0";
}
.icon-sunset:before {
  content: "\e8e1";
}
.icon-sunrise:before {
  content: "\e8e2";
}
.icon-sun:before {
  content: "\e8e3";
}
.icon-cloud:before {
  content: "\e8e4";
}
.icon-cloud-snow:before {
  content: "\e8e5";
}
.icon-cloud-rain:before {
  content: "\e8e6";
}
.icon-cloud-lightning:before {
  content: "\e8e7";
}
.icon-cloud-drizzle:before {
  content: "\e8e8";
}
.icon-activity:before {
  content: "\e8e9";
}
.icon-idea:before {
  content: "\e8ea";
}
.icon-pocket:before {
  content: "\e8eb";
}
.icon-scissors:before {
  content: "\e8ec";
}
.icon-book-open:before {
  content: "\e8ed";
}
.icon-briefcase:before {
  content: "\e8ee";
}
.icon-life-buoy:before {
  content: "\e8ef";
}
.icon-anchor:before {
  content: "\e8f0";
}
.icon-globe:before {
  content: "\e8f1";
}
.icon-compass:before {
  content: "\e8f2";
}
.icon-award:before {
  content: "\e8f3";
}
.icon-coffee:before {
  content: "\e8f4";
}
.icon-target:before {
  content: "\e8f5";
}
.icon-credit-card:before {
  content: "\e8f6";
}
.icon-box:before {
  content: "\e8f7";
}
.icon-truck:before {
  content: "\e8f8";
}
.icon-shopping-bag:before {
  content: "\e8f9";
}
.icon-package:before {
  content: "\e8fa";
}
.icon-shopping-cart:before {
  content: "\e8fb";
}
.icon-book:before {
  content: "\e8fc";
}
.icon-truck-2:before {
  content: "\e8fd";
}
.icon-tool:before {
  content: "\e8fe";
}
.icon-server:before {
  content: "\e8ff";
}
.icon-key:before {
  content: "\e900";
}
.icon-hard-drive:before {
  content: "\e901";
}
.icon-database:before {
  content: "\e902";
}
.icon-cpu:before {
  content: "\e903";
}
.icon-mail_lre:before {
  content: "\e904";
}
.icon-mail_cc:before {
  content: "\e905";
}
.icon-mail_cle:before {
  content: "\e906";
}
.icon-file_xls:before {
  content: "\e907";
}
.icon-file_jpg:before {
  content: "\e908";
}
.icon-file_pdf:before {
  content: "\e909";
}
.icon-maximize-2:before {
  content: "\e90a";
}
.icon-archive:before {
  content: "\e90b";
}
.icon-link-2:before {
  content: "\e90c";
}
.icon-layers:before {
  content: "\e90d";
}
.icon-zone:before {
  content: "\e90e";
}
.icon-maximize:before {
  content: "\e90f";
}
.icon-minimize:before {
  content: "\e910";
}
.icon-columns:before {
  content: "\e911";
}
.icon-sidebar:before {
  content: "\e912";
}
.icon-layout:before {
  content: "\e913";
}
.icon-grid:before {
  content: "\e914";
}
.icon-zoom-out:before {
  content: "\e915";
}
.icon-zoom-in:before {
  content: "\e916";
}
.icon-git-pull-request:before {
  content: "\e917";
}
.icon-git-branch:before {
  content: "\e918";
}
.icon-crosshair:before {
  content: "\e919";
}
.icon-crop:before {
  content: "\e91a";
}
.icon-copy:before {
  content: "\e91b";
}
.icon-git-merge:before {
  content: "\e91c";
}
.icon-edit-2:before {
  content: "\e91d";
}
.icon-sliders:before {
  content: "\e91e";
}
.icon-move:before {
  content: "\e91f";
}
.icon-list:before {
  content: "\e920";
}
.icon-edit:before {
  content: "\e921";
}
.icon-edit-3:before {
  content: "\e922";
}
.icon-pen-tool:before {
  content: "\e923";
}
.icon-italic:before {
  content: "\e924";
}
.icon-bold:before {
  content: "\e925";
}
.icon-underline:before {
  content: "\e926";
}
.icon-type:before {
  content: "\e927";
}
.icon-git-commit:before {
  content: "\e928";
}
.icon-align-right:before {
  content: "\e929";
}
.icon-align-left:before {
  content: "\e92a";
}
.icon-align-justify:before {
  content: "\e92b";
}
.icon-align-center:before {
  content: "\e92c";
}
.icon-sign-2:before {
  content: "\e92d";
}
.icon-sign:before {
  content: "\e92e";
}
.icon-inbox:before {
  content: "\e92f";
}
.icon-folder:before {
  content: "\e930";
}
.icon-folder-plus:before {
  content: "\e931";
}
.icon-folder-minus:before {
  content: "\e932";
}
.icon-file:before {
  content: "\e933";
}
.icon-file-text:before {
  content: "\e934";
}
.icon-file-plus:before {
  content: "\e935";
}
.icon-file-minus:before {
  content: "\e936";
}
.icon-clipboard:before {
  content: "\e937";
}
.icon-alert-triangle:before {
  content: "\e938";
}
.icon-flag:before {
  content: "\e939";
}
.icon-home:before {
  content: "\e93a";
}
.icon-search:before {
  content: "\e93b";
}
.icon-framer:before {
  content: "\e93d";
}
.icon-codepen:before {
  content: "\e93e";
}
