/**
Montserrat
*/
$Montserrat: "Montserrat", "System-UI", "Helvetica", "Arial", sans-serif;
$MontserratSemiBold: "Montserrat-semiBold", "System-UI", "Helvetica", "Arial", sans-serif;
$MontserratMedium: "montserrat-medium", "System-UI", "Helvetica", "Arial", sans-serif;
$MontserratBold: "montserrat-bold", "System-UI", "Helvetica", "Arial", sans-serif;

/**
Barlow
 */
$Barlow: "Barlow", "System-UI", "Helvetica", "Arial", sans-serif;
$BarlowMedium: "Barlow-medium", "System-UI", "Helvetica", "Arial", sans-serif;
$BarlowBold: "Barlow-Bold", "System-UI", "Helvetica", "Arial", sans-serif;
$BarlowSemiBold: "Barlow-semiBold", "System-UI", "Helvetica", "Arial", sans-serif;
$BarlowItalic: "barlow-italic", "System-UI", "Helvetica", "Arial", sans-serif;
$BarlowLightItalic: "barlow-light-italic", "System-UI", "Helvetica", "Arial", sans-serif;
$BarlowBoldItalic: "barlow-bold-italic", "System-UI", "Helvetica", "Arial", sans-serif;
$BarlowLight: "barlow-light", "System-UI", "Helvetica", "Arial", sans-serif;

/**
Arial
*/
$Arial: Arial, sans-serif;

$iconDocaposte: "icon-docaposte";
