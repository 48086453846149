// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

//btn overwride bootstrap

@import "basics/fonts";

.btn {
  line-height: $btn-line-height;
  &.btn-sm {
    line-height: $btn-line-height-sm;
  }
  //btn-link
  &.btn-link {
    border: none;
    padding: 0;
    background: none;
  }
  // Disabled
  &.disabled,
  &:disabled {
    color: $gray-300;
    border-color: $gray-100;
    cursor: not-allowed;
  }
  // Disabled
  &:not(.btn-link).disabled,
  &:not(.btn-link):disabled {
    background: $gray-100;
    border-color: $gray-100;
    color: #c6c8d0;
  }

  // If btn with icon remove margin and new padding
  &.btn-icon {
    padding-left: 0;
    padding-right: 0;
    //icon on left
    i {
      padding-left: calc(#{$btn-padding-x} - 0.25rem);
      padding-right: 0.5rem;
      font-size: $btn-line-height;
      & + span {
        padding-left: 0;
        padding-right: $btn-padding-x;
      }
    }
    //icon on right
    span {
      padding-left: $btn-padding-x;
    }
    span + i {
      padding-right: calc(#{$btn-padding-x} - 0.25rem);
      padding-left: 0.5rem;
    }
    &.btn-sm {
      //icon on left
      i {
        padding-left: calc(#{$btn-padding-x-sm} - 0.25rem);
        padding-right: 0.5rem;
        font-size: $btn-line-height-sm;
        & + span {
          padding-left: 0;
          padding-right: $btn-padding-x-sm;
        }
      }
      //icon on right
      span {
        padding-left: $btn-padding-x-sm;
      }
      span + i {
        padding-right: calc(#{$btn-padding-x-sm} - 0.25rem);
        padding-left: 0.5rem;
      }
    }
    &.btn-lg {
      //icon on left
      i {
        padding-left: calc(#{$btn-padding-x-lg} - 0.25rem);
        padding-right: 0.5rem;
        font-size: $btn-line-height-lg;
        & + span {
          padding-left: 0;
          padding-right: $btn-padding-x-lg;
        }
      }
      //icon on right
      span {
        padding-left: $btn-padding-x-lg;
      }
      span + i {
        padding-right: calc(#{$btn-padding-x-lg} - 0.25rem);
        padding-left: 0.5rem;
      }
    }
  }
}
//responsive
@include media-breakpoint-down(md) {
  .btn.btn-icon,
  .btn.btn-icon.btn-sm,
  .btn.btn-icon.btn-lg {
    padding: 0;
    span {
      display: none;
    }
  }
  .btn.btn-icon i {
    padding: $btn-padding-y-sm !important;
  }
  .btn.btn-icon.btn-sm i {
    padding: $btn-padding-y-sm !important;
  }
  .btn.btn-icon.btn-lg i {
    padding: $btn-padding-y-lg !important;
  }
}

.primary-btn {
  color: var(--Background-White, #fff);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  font-family: $MontserratSemiBold;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 115%; /* 1.00625rem */
  letter-spacing: -0.00875rem;
  border-radius: 1.6875rem;

  display: flex;
  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  border: none;
  background: var(--Action-Cobalt-100, #00f);
  &:hover,
  &:focus {
    color: var(--Background-White, #fff);
    background: var(--action-hover-focus, #3351e0);
  }
  &:active {
    color: var(--Background-White, #fff);
    background: var(--Action-Click, #001eae);
  }
  &:disabled {
    color: var(--Values-Grey-30, #c2c5d3);
    background: var(--Values-Grey-10, #ededf2);
  }
}

.secondary-btn {
  color: var(--Action-Cobalt-100, #00f);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  font-family: $MontserratSemiBold;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 115%; /* 1.00625rem */
  letter-spacing: -0.00875rem;
  border-radius: 1.6875rem;

  display: flex;
  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  border: 1px solid var(--Action-Cobalt-100, #00f);
  background: white;

  &:hover,
  &:focus {
    color: var(--action-hover-focus, #3351e0);
    background: var(--Action-Cobalt-05, #f2f2ff);
    border: 1px solid var(--Values-Grey-10, #3351e0);
  }

  &:active {
    color: var(--action-hover-focus, #001eae);
    background: var(--Action-Cobalt-10, #e5e5fd);
    border: 1px solid var(--Values-Grey-10, #001eae);
  }
  &:disabled {
    color: var(--Values-Grey-30, #c2c5d3);
    background: var(--Action-Cobalt-05, #ffffff);
    border: 1px solid var(--Values-Grey-10, #ededf2);
  }
}

.ghost-btn {
  color: var(--Action-Cobalt-100, #00f);
  text-align: right;
  font-feature-settings: "clig" off, "liga" off;

  font-family: $MontserratSemiBold;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 115%; /* 1.00625rem */
  letter-spacing: -0.00875rem;

  display: flex;
  padding: 0.75rem 0rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  border: none;
  background: white;

  &:hover,
  &:focus {
    color: var(--action-hover-focus, #3351e0);
  }
  &:active {
    color: var(--Action-Click, #001eae);
  }
  &:disabled {
    color: var(--Values-Grey-30, #c2c5d3);
  }
}
