// Checkboxes

.custom-switch.custom-switch {
  padding-left: 2rem;
  padding-bottom: 1.5rem;

  .custom-control-label {
    padding-left: 1.5rem;
    line-height: 2rem;
    color: var(--Text-Ultramarine, #00008c);
    font-feature-settings: "clig" off, "liga" off;
    /* Headlines/H5 */
    font-family: $Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.406px;

    &::before {
      height: 1.5rem;
      width: calc(2rem + 0.75rem);
      border-radius: 3rem;
    }

    &::after {
      width: calc(1.5rem - 4px);
      height: calc(1.5rem - 4px);
      border-radius: calc(2rem - (1.5rem / 2));
      background: white;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(calc(1.5rem - 0.25rem));
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #ffffff;
    border-color: var(--Background-User-Blue, #3970e6);
    background: var(--Background-User-Blue, #3970e6);
  }

  .custom-control-input:disabled ~ .custom-control-label::before {
    background: var(--Values-Grey-10, #ededf2);
    border-color: var(--Values-Grey-10, #ededf2);
  }

  .custom-control-label::before {
    background: #666d92;
  }
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .form-check {
    position: relative;
    display: flex;
    font-size: 0;
    padding: 0;
    box-sizing: border-box;

    &.switch {
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: row-reverse;
    }
  }
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    label {
      margin-bottom: 0;
    }
  }
  //error
  .errors {
    input[type="checkbox"],
    input[type="password"],
    input[type="radio"] {
      //radio and checkbox square
      &:not([class^="switch"]) {
        border: $checkbox-border-size solid $danger;
      }
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    --checked: #{$checkbox-checked-bg};
    --unchecked: #{$checkbox-unchecked-bg};
    --checked-disabled: #{lighten($checkbox-unchecked-bg, 20%)};
    --unchecked-disabled: #{lighten($checkbox-unchecked-bg, 40%)};
    --background-checked-disabled: #{lighten($checkbox-checked-bg, 20%)};
    --background-unchecked-hover: #{lighten($checkbox-checked-bg, 38%)};
    --checked-inner: #{$white};
    --focus: 0 0 0 3px rgba(104, 132, 255, 0.3);
    --border: #{lighten($checkbox-unchecked-bg, 30%)};
    --border-hover: #{$checkbox-checked-bg};
    --background: #{$white};

    -webkit-appearance: none;
    -moz-appearance: none;
    //outline: none;
    //display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: $checkbox-border-size $checkbox-border-style var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background $checkbox-delay, border-color $checkbox-delay, box-shadow $checkbox-delay;

    height: $checkbox-size;
    & + label {
      font-size: $label-font-size-md;
      line-height: $checkbox-size;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
    }

    //Small
    &[class$="sm"] {
      height: $checkbox-size-sm;
      & + label {
        font-size: $label-font-size-sm;
        line-height: $checkbox-size-sm;
      }
    }

    //Large
    &[class$="lg"] {
      height: $checkbox-size-lg;
      & + label {
        font-size: $label-font-size-lg;
        line-height: $checkbox-size-lg;
      }
    }

    &:after {
      content: "";
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, $checkbox-delay) var(--d-t-e, ease), opacity var(--d-o, $checkbox-delay);
    }
    &:checked {
      --b: var(--checked);
      --bc: var(--checked);
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }
    &:disabled {
      --b: var(--unchecked-disabled);
      cursor: not-allowed;
      opacity: 0.9;
      &:checked {
        --b: var(--checked-disabled);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover:not([class^="switch"]) {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
          --b: var(--background-unchecked-hover);
        }
      }
    }
    &:focus {
      box-shadow: var(--focus);
    }
    &:not([class^="switch"]) {
      width: $checkbox-size;
      margin-right: calc(#{$spacer} * 0.5);
      //Small
      &[class$="sm"] {
        margin-right: calc(#{$spacer} * 0.25);
        width: $checkbox-size-sm;
      }
      //Large
      &[class$="lg"] {
        width: $checkbox-size-lg;
        margin-right: calc(#{$spacer} * 0.75);
      }
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
  }
  input[type="checkbox"] {
    &:not([class^="switch"]) {
      border-radius: 0px !important;
      &:after {
        content: "\e80d";
        font-size: 0;
        text-align: center;
        font-family: $iconDocaposte;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &::before {
        background-color: #666d92;
      }
      &:disabled {
        --b: var(--unchecked-disabled);
      }
      &:checked {
        background-color: #3970e6 !important;
        &:disabled {
          --b: var(--checked-disabled);
        }
        &:after {
          color: white;
          font-size: calc(#{$checkbox-size} - (#{$checkbox-checkbox-padding}));
        }
      }
      //Small
      &[class$="sm"] {
        border-radius: 4px;
        &:checked {
          &:after {
            color: white;
            background-color: #3970e6;
            font-size: calc(#{$checkbox-size-sm} - (#{$checkbox-checkbox-padding}));
          }
        }
      }
      //Large
      &[class$="lg"] {
        &:checked {
          &:after {
            color: white;
            font-size: calc(#{$checkbox-size-lg} - (#{$checkbox-checkbox-padding}));
          }
        }
      }
    }
    &[class^="switch"] {
      width: $checkbox-switch-width;
      border-radius: 25px;
      background-color: var(--bg, var(--unchecked)) !important;
      border-color: var(--bg, var(--unchecked));
      height: calc(#{$checkbox-size + ($checkbox-border-size * 2)});
      &:after {
        left: $checkbox-border-size;
        top: $checkbox-border-size;
        border-radius: 50%;
        width: calc(#{$checkbox-size} - (#{$checkbox-border-size * 2}));
        height: calc(#{$checkbox-size} - (#{$checkbox-border-size * 2}));
        background: var(--checked-inner);
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --bg: var(--checked) !important;
        --x: calc(#{($checkbox-switch-width) - ($checkbox-size + ($checkbox-border-size * 2))});
      }
      &:disabled {
        &:not(:checked) {
          --bc: var(--border);
          --bg: var(--border);
        }
        --bc: var(--background-checked-disabled);
        --bg: var(--background-checked-disabled);
      }
      //Small
      &[class$="sm"] {
        height: calc(#{$checkbox-size-sm + ($checkbox-border-size * 2)});
        width: $checkbox-switch-width-sm;
        &:after {
          width: calc(#{$checkbox-size-sm} - (#{$checkbox-border-size * 2}));
          height: calc(#{$checkbox-size-sm} - (#{$checkbox-border-size * 2}));
        }
        &:checked {
          --x: calc(#{($checkbox-switch-width-sm) - ($checkbox-size-sm + ($checkbox-border-size * 2))});
        }
      }

      //large
      &[class$="lg"] {
        height: calc(#{$checkbox-size-lg + ($checkbox-border-size * 2)});

        width: $checkbox-switch-width-lg;
        &:after {
          width: calc(#{$checkbox-size-lg} - (#{$checkbox-border-size * 2}));
          height: calc(#{$checkbox-size-lg} - (#{$checkbox-border-size * 2}));
        }
        &:checked {
          --x: calc(#{($checkbox-switch-width-lg) - ($checkbox-size-lg + ($checkbox-border-size * 2))});
        }
      }
    }
  }
  input[type="radio"] {
    border-radius: $checkbox-radio-border-radius;
    &:after {
      width: calc(#{$checkbox-size} - #{$checkbox-border-size * 2});
      height: calc(#{$checkbox-size} - #{$checkbox-border-size * 2});
      border-radius: $checkbox-radio-border-radius;
      background: var(--b, var(--checked-inner));
      opacity: 0;
      transform: scale(var(--s, 0.7));
    }
    &:checked {
      --s: 0.5;
      background-color: #3970e6 !important;
      &:after {
        --b: var(--checked-inner);
      }
      &:disabled {
        --b: var(--unchecked-disabled);
        &:after {
          --b: var(--checked-disabled);
        }
      }
    }
    //Small
    &[class$="sm"] {
      &:after {
        width: calc(#{$checkbox-size-sm} - #{$checkbox-border-size * 2});
        height: calc(#{$checkbox-size-sm} - #{$checkbox-border-size * 2});
      }
    }
    //Large
    &[class$="lg"] {
      &:after {
        width: calc(#{$checkbox-size-lg} - #{$checkbox-border-size * 2});
        height: calc(#{$checkbox-size-lg} - #{$checkbox-border-size * 2});
      }
    }
  }
}
