//
// Basic Bootstrap table
//

.table {
  td,
  thead th {
    text-align: center;
  }

  thead th {
    border: none;
  }
}

// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

//ngx-datatable
.ngx-datatable {
  .datatable-header-cell.press .datatable-header-cell-label.draggable {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  .datatable-header-cell .datatable-header-cell-label.draggable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  .datatable-body-row.active .datatable-row-center {
    background: $digital;
  }

  .icon-reverse {
    cursor: pointer;
  }

  .datatable-row-detail {
    display: none;
  }

  .datatable-scroll {
    width: 100% !important;
  }

  .datatable-body-row .datatable-body-cell,
  .datatable-header-cell-template-wrap,
  .datatable-header {
    display: flex;
    align-items: center;
  }

  .datatable-header .datatable-row-center,
  .datatable-body .datatable-row-center {
    padding: $datatable-row-padding-y $datatable-row-padding-x;
  }

  .datatable-row-center,
  .datatable-header {
    background: $datatable-bg;
    margin-bottom: 3px;
  }

  .datatable-body-cell,
  .datatable-header-cell {
    &:first-child,
    &:last-child {
      padding: 0;
    }

    padding: 0 0.75rem;
  }

  .datatable-header {
    display: flex !important;
    border-bottom: double;
  }

  .datatable-body-cell {
    align-self: center;
    height: auto !important;
  }

  .datatable-checkbox {
    margin-bottom: 0;
    display: inline-block;
    font-size: 1rem !important;
    font-family: $Barlow;
    font-weight: 400;
    color: #666d92;
    padding: 0.9375rem 1.5625rem 0.9375rem 3.4375rem;
    position: relative;
    cursor: pointer;

    &:before {
      content: "";
      border: 0.0625rem solid #666d92;
      background: rgba(102, 109, 146, 0.05);
      -moz-border-radius: 0.3125rem;
      border-radius: 0.3125rem;
    }
  }

  .datatable-checkbox:after,
  .datatable-checkbox:before {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    height: 1rem;
    width: 1rem;
  }

  .datatable-checkbox:after {
    content: "\f26b";
    background: #3363cc;
    border: 0.0625rem solid #00f;
    -moz-border-radius: 0.3125rem;
    border-radius: 0.3125rem;
    font-family: Material-Design-Iconic-Font;
    font-size: 0.875rem;
    text-align: center;
    line-height: 1rem;
    color: #fff;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  datatable-body-row.active,
  datatable-header-cell[ng-reflect-all-rows-selected="true"] {
    .datatable-checkbox:after {
      opacity: 1;
    }
  }

  .datatable-body-cell-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .datatable-footer {
    overflow: unset !important;
  }
}

//footer
.ngx-datatable .datatable-footer .selected-count .datatable-pager {
  flex: 1;
  text-align: center;

  ul {
    background: $datatable-footer-bg;
    padding: $datatable-footer-padding-y $datatable-footer-padding-x;
    position: relative;
    border-radius: $datatable-footer-border-radius;
    box-shadow: $datatable-footer-box-shadow;

    li:nth-child(2),
    li:nth-last-child(1),
    li:first-child,
    li:nth-last-child(2) {
      position: absolute;
      bottom: 0;
      background: $datatable-footer-bg;
      border-radius: $datatable-footer-border-radius;
      box-shadow: $datatable-footer-box-shadow;
      width: calc(33px + #{$datatable-footer-padding-x * 2});
      line-height: calc(33px + #{$datatable-footer-padding-y * 2});
    }

    li:nth-last-child(1) {
      right: 0;
      transform: translateX(200%);
      margin-right: -2rem;
    }

    li:first-child {
      left: 0;
      transform: translateX(-200%);
      margin-left: -2rem;
    }

    li:nth-last-child(2) {
      right: 0;
      transform: translateX(100%);
      margin-right: -1rem;
    }

    li:nth-child(2) {
      left: 0;
      transform: translateX(-100%);
      margin-left: -1rem;
    }

    li.pages {
      width: 33px;
      line-height: 33px;

      &.active,
      &.hover {
        background: $primary;
        border-radius: 1.5rem;

        a {
          color: $white;
        }
      }
    }

    li.disabled {
      a {
        color: $btn-disabled-bg;
      }
    }
  }
}

//responsive
@include media-breakpoint-down(md) {
  .datatable-row-detail {
    display: block !important;
    padding: 1.375rem 1.5rem 1.375rem 0;
    z-index: 1;
    white-space: normal;
    flex-grow: 1;
  }
  .ngx-datatable .datatable-body .datatable-row-wrapper {
    display: flex;
    flex-direction: row !important;
    background: white;
    border-radius: $datatable-row-detail-border-radius;
    margin-bottom: 3px;
    align-items: center;
    position: relative;
  }
  .datatable-body-row {
    flex-grow: 0;
  }
  .ngx-datatable .datatable-body .datatable-row-center {
    padding-right: 0;
  }
  .datatable-body-row,
  .datatable-row-center {
    width: auto !important;
  }
  .datatable-body-row.active:before {
    content: "";
    background: $digital;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: $datatable-row-detail-border-radius;
  }
}

@include media-breakpoint-down(sm) {
  .ngx-datatable .datatable-header {
    display: none !important;
  }
  //footer
  .ngx-datatable .datatable-footer .selected-count .datatable-pager {
    flex: 1;
    text-align: center;

    ul li.pages:not(.active) {
      display: none;
    }
  }
}

app-circuit-home-page
  .ngx-datatable.fixed-row
  .datatable-scroll
  .datatable-body-row
  .datatable-body-cell:not(:first-child),
app-circuit-home-page .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-group-cell {
  margin-left: 20px;
}

app-contact-home-page
  .ngx-datatable.fixed-row
  .datatable-scroll
  .datatable-body-row
  .datatable-body-cell:not(:first-child),
app-contact-home-page .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-group-cell {
  margin-left: 20px;
}

app-template-home-page
  .ngx-datatable.fixed-row
  .datatable-scroll
  .datatable-body-row
  .datatable-body-cell:not(:first-child),
app-template-home-page .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-group-cell {
  margin-left: 30px;
}

app-template-home-page
  .ngx-datatable
  .datatable-header
  .datatable-header-cell:nth-child(2)
  .datatable-header-cell-template-wrap {
  margin-left: 5px;
}
