//btn variant
@each $color, $value in $theme-colors {
  .border-#{$color} {
    @include border-variant($value);
  }
}

@for $i from 1 through 5 {
  .border-#{$i} {
    @include border-size($i);
  }
}
