@mixin animation($animate...) {
  $max: length($animate);
  $animations: "";

  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};

    @if $i < $max {
      $animations: #{$animations + ", "};
    }
  }
  -webkit-animation: $animations;
  -moz-animation: $animations;
  -o-animation: $animations;
  animation: $animations;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    $browser: "-webkit-";
    @content;
  }
  @-moz-keyframes #{$animationName} {
    $browser: "-moz-";
    @content;
  }
  @-o-keyframes #{$animationName} {
    $browser: "-o-";
    @content;
  }
  @keyframes #{$animationName} {
    $browser: "";
    @content;
  }
}
$browser: null;
